<script>
      import { createEventDispatcher } from "svelte";
      import IconoNico from "./IconoNico.svelte";

      export let texto = "Guardar";
      export let textoCargando = "Guardando..";
      export let clases = "";
      export let clasesCont = "";
      // export let bgColor = '#f1c40f';
      export let bgColor = "#7f56da";
      export let icono = "check";
      export let colorIcono = "white";
      // export const icono = "fa-brands fa-paypal";
      // export let click; // Exporta la función que se pasará como evento onclick
      export let cargando = false;
      export let debug = false;
      export let outline = false;
      export let fnClick;
      export let ptb = "10px";
      export let plr = "20px";
      function handleClick() {
            console.log("CLICCC: " + fnClick);
            if (fnClick != null) {
                  fnClick();
            }
      }
      // Crear el despachador de eventos
      // const dispatch = createEventDispatcher();
</script>

<div class="cont-boton flex-center {clasesCont}">
      <!-- on:click={() => dispatch("click")} -->
      <!-- class="btn-material" -->
      {#if outline}
            <!-- 1 - BOTON OUTLINE:-->
            <button
                  class="btn-material-outline {clases}"
                  on:click={() => handleClick()}
                  disabled={cargando}
                  style="border: solid 1px {bgColor};
                  padding-top: {ptb};
                  padding-bottom: {ptb};
                  padding-left: {plr};
                  padding-right: {plr}"
            >
                  <div class="">
                        {#if !cargando}
                              <span style="color: {bgColor}">
                                    {#if icono != "none"}
                                          <!-- <Icon name="check" /> -->
                                          <IconoNico name={icono} color={bgColor} />
                                    {/if}
                                    <!-- {icono} -->
                                    {@html texto}
                              </span>
                        {:else}
                              <span>
                                    {#if icono != "none"}
                                          <IconoNico name="spinner" color={bgColor} />
                                    {/if}
                                    {textoCargando}
                              </span>
                        {/if}
                  </div>
            </button>
      {:else}
            <!-- 1 - BOTON FILL MATERIAL:-->
            <button
                  class="btn-material {clases}"
                  on:click={() => handleClick()}
                  disabled={cargando}
                  style="background-color:{bgColor};
                  padding-top: {ptb};
                  padding-bottom: {ptb};
                  padding-left: {plr};
                  padding-right: {plr};"
            >
                  {#if !cargando}
                        <div class="flex">
                              {#if icono != "none"}
                                    <!-- <Icon name="check" /> -->
                                    <IconoNico name={icono} color={colorIcono} />
                              {/if}
                              <span>
                                    <!-- {icono} -->
                                    {@html texto}
                              </span>
                        </div>
                  {:else}
                        <div class="flex">
                              {#if icono != "none"}
                                    <IconoNico name="spinner" color={colorIcono} />
                              {/if}
                              <span>
                                    {textoCargando}
                              </span>
                        </div>
                  {/if}
            </button>
      {/if}
</div>
{#if debug}
      <br />
      Cargando:{cargando}
{/if}

<style>
      .cont-boton {
            padding-bottom: 12px;
      }
      .btn-material {
            color: #fff;
            padding: 10px 20px;
            border-radius: 50px;
            cursor: pointer;
      }
      .btn-material-outline {
            padding: 10px 20px;
            border-radius: 50px;
            cursor: pointer;
            background-color: transparent;
            /* border: solid 1px var(--bgColor); */
            /* color: var(--bgColor); */
            /* padding: 10px 20px;
            border-radius: 50px;
            cursor: pointer; */
      }
</style>
