import { screenSize } from "../stores/screen-store";

export let xl = false;
export let lg = false;
export let md = false;
export let sm = false;
export let xs = true;
export let verbose = true;
export let debug = false;

// Función para evaluar el tamaño de la ventana
export function evaluateWindowSize() {
      const ancho = window.innerWidth;

      // Forzar ancho mínimo
      // if (ancho < 350) {
      //       console.log("ANCHO MENOR A 350");
      //       document.body.style.width = "350px";
      // } else {
      //       document.body.style.width = "auto"; // O el valor original que desees
      // }

      let sizeData = {
            xl: false,
            lg: false,
            md: false,
            sm: false,
            xs: true,
      };

      if (ancho > 1200) {
            sizeData.xl = true;
            sizeData.lg = false;
            sizeData.md = false;
            sizeData.sm = false;
            sizeData.xs = false;
      } else if (ancho < 1200 && ancho > 992) {
            sizeData.xl = false;
            sizeData.lg = true;
            sizeData.md = false;
            sizeData.sm = false;
            sizeData.xs = false;
      } else if (ancho < 992 && ancho > 768) {
            sizeData.xl = false;
            sizeData.lg = false;
            sizeData.md = true;
            sizeData.sm = false;
            sizeData.xs = false;
      } else if (ancho < 768 && ancho > 576) {
            sizeData.xl = false;
            sizeData.lg = false;
            sizeData.md = false;
            sizeData.sm = true;
            sizeData.xs = false;
      } else {
            sizeData.xl = true;
            sizeData.lg = false;
            sizeData.md = false;
            sizeData.sm = false;
            sizeData.xs = true;
      }

      screenSize.set(sizeData); // Actualiza el store
}
