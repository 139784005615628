<div class="item-archivo-attached">
      <!-- 1 - FILA 1: -->
      <div class="fila-1 col-12 flex">
            <!-- 1.1 - FILA 1 - COL 1: -->
            <div class="cont-img-archivo-attached" style="width: 60px;height:100%;">
                  <!-- 1.1.1 - IMAGEN ARCHIVO SUBIDO: -->
                  <ImgBordecitoBlanco
                        src={archivoBack?.previewDocumento}
                        w="100%"
                        h="55px"
                        br="10px"
                  />
            </div>
            <!-- 1.2 - FILA 1 - COL 2: -->
            <div class="cont-textos-item-archivo pl-3" style="width: calc(100% - 92px)">
                  <!-- 1.2.1 - CARTEL CARNICELERO NOMBRE:-->
                  <div class="">
                        <HCarnicero
                              texto={archivoBack?.id + " - " + archivoBack?.urlOriginal}
                              w="100%"
                              pt="4px"
                              pb="4px"
                        />
                  </div>
                  <!-- 1.2.2 - CARTEL CARNICELERO EXTENSION:-->
                  <div class="col-12">
                        <H3
                              style="padding-left:15px"
                              texto={"Extension: " + archivoBack?.calcularExtension}
                        />
                  </div>
            </div>
            <!-- 1.3 - FILA 1 - COL 3: -->
            <div
                  class="cont-textos-item-archivo flex-end flex-v"
                  style="width: 52px; padding-top: 12px; padding-left: 6px; padding-right: 6px"
            >
                  <!-- 99 - BOTON RM FILE:-->
                  <BotoN
                        texto="RM"
                        icono="trash"
                        bind:cargando={cargandoRM}
                        color="#445156"
                        fnClick={() => RMFILE(archivoBack?.id)}
                  />
            </div>
      </div>
      <!-- FILA 2: -->
      <div class="fila-2">
            <!-- <BarraProgreso h="5px" showPercentaje={false} /> -->
      </div>

      <!-- <div class="col-6 bsr flex-center-h flex-center-v">
        <div class="col-12 bsr">
            <HCarnicero texto={archivoBack?.urlOriginal} />
        </div>
        <div class="col-12">
            <h3 class="h-extension bsr">
                {archivoBack?.calcularExtension}
            </h3>
        </div>
    </div>
    <div class="col-3"></div>
    
    <div class="col-12">
    </div> -->
      <!-- {JSON.stringify(archivoBack)} -->
      <!-- {archivoBack} -->
</div>

<script>
      import { GATEWAY } from "../../stores/gateway-store";
      import BarraProgreso from "./BarraProgreso.svelte";
      import HCarnicero from "./HCarnicero.svelte";
      import BotonNico from "./BotonNico.svelte";
      import Img from "./Img.svelte";
      import ImgBordecitoBlanco from "./ImgBordecitoBlanco.svelte";
      import H3 from "./H3.svelte";
      import BotoN from "./BotoN.svelte";
      export let archivoBack;
      export let arrFilesToRemove = [];
      let cargandoRM = false;

      async function RMFILE(fileID) {
            console.log("RM FILE:" + fileID);

            const urlEndpoint = $GATEWAY.url + "upload/doc/factura/" + fileID;
            cargandoRM = true;
            console.log("RM DOCUMENTO(" + fileID + ") DE FACTURA: ");
            try {
                  const response = await fetch(urlEndpoint, {
                        method: "DELETE",
                        headers: {
                              "Content-Type": "application/json",
                        },
                  });

                  if (response.ok) {
                        removeFileFromList(fileID);
                        cargandoRM = false;
                  } else {
                        cargandoRM = false;
                        alert("NO SE PUDO ELIMINAR EL ARCHIVO: " + fileID);
                  }
            } catch (error) {
                  console.error("Error:", error);
                  cargandoRM = false;
            }
      }
      function removeFileFromList(fileID) {
            console.log("quitando: " + fileID);
            if (arrFilesToRemove) {
                  arrFilesToRemove = arrFilesToRemove.filter((fileLoop) => fileLoop.id !== fileID);
            }
      }
</script>

<style>
      .item-archivo-attached {
            margin-bottom: 15px;
            border-radius: 12px;
            background-color: #f7f8f8;
            padding: 12px;
            border: solid 1px lightgrey;
      }
      .h-extension {
            font-size: 14px;
      }
      .cont-img-archivo-attached {
            width: 30px;
            height: 30px;
      }
      /* .h-extension {
            padding-left: 15px;
            font-weight: thin;
            color: #aeafb0;
      } */
</style>
