<script>
      import Icon from "../basics/IconoNico.svelte";
      import CuadradoOpLogeado from "./CuadradoOpYALogeado.svelte";
      export let RUTAS_HUMAN = []; // Recibe las rutas de navegación desde App.svelte
      export let RUTA_ACTUAL = ""; // Recibe la ruta actual desde App.svelte
      export let color = "orange"; // Recibe la ruta actual desde App.svelte
      export let colorText = "white"; // Recibe la ruta actual desde App.svelte
      export let fnExit;

      function clickExit() {
            if (fnExit != null) {
                  // let tipo = typeof fnExit;
                  fnExit();
            } else {
                  alert("FUNCION EXIT = NULL");
                  console.log("FUNCION EXIT = NULL");
            }
      }
</script>

<div class="cont-banner flex col-12" style="background-color:{color}; color: {colorText}">
      <div class="banner-izq partes-banner flex-center-h flex-center-v">
            <Icon name="bars" clase="pointer" />
      </div>
      <div class="banner-mid partes-banner flex-start">
            {#each RUTAS_HUMAN as routeHumanLoop}
                  <span
                        class="menu-item flex-center-h flex-center-v raleway-thin"
                        class:selected={routeHumanLoop.path === RUTA_ACTUAL}
                  >
                        <a href={`#${routeHumanLoop.path}`} style="color:{colorText}">
                              <div class="ruta-texto">
                                    {routeHumanLoop.name}
                              </div>
                              <div class="cont-hr-menu-item flex-center-h pt-1">
                                    <hr class="hr" />
                              </div>
                        </a>
                  </span>
            {/each}
      </div>
      <div class="banner-der partes-banner col-3 flex-center-h flex-center-v">
            <CuadradoOpLogeado />
      </div>
</div>

<style>
      a {
            text-decoration: none;
      }
      .selected {
            /* border: solid 1px white; */
            font-weight: bold;
            /* background-color: rgba(0, 0, 0, 0.25); */
            /* color: blue; */
      }
      .partes-banner {
            height: 50px;
            /* border: solid 1px red; */
      }
      .banner-izq {
            width: 50px;
      }
      .banner-der {
            /* width: 150px; */
      }
      .banner-mid {
            width: calc(100vw - 200px);
            padding-left: 12px;
            padding-right: 12px;
            overflow-x: auto;
      }
      .banner-mid::-webkit-scrollbar {
            height: 4px;
      }
      .banner-mid::-webkit-scrollbar-track {
            border-radius: 50px;
            background-color: #e3e3e3;
            overflow: hidden;
      }
      .banner-mid::-webkit-scrollbar-thumb {
            border-radius: 50px;
            background-color: grey;
            overflow: hidden;
            /* background-color: rbga(0, 0, 0, 0.9); Color de la barra de desplazamiento */
      }

      .menu-item {
            width: fit-content;
            padding: 6px;
            padding-left: 12px;
            padding-right: 12px;
            /* background-color: rgba(0, 0, 0, 0.25); */
            border-radius: 50px;
            text-align: center;
            margin-right: 8px;
      }
      .menu-item:hover {
            /* border-bottom: solid 1px white; */
            transition: 1s;
      }
      .menu-item:hover .cont-hr-menu-item,
      .menu-item:hover .hr {
            display: flex; /* Muestra el <hr> al hacer hover */
            text-align: center;
      }
      .cont-hr-menu-item {
            display: none; /* Oculta el <hr> por defecto */
      }

      .hr {
            display: none; /* Oculta el <hr> por defecto */
            border-bottom: solid 1px white;
            width: 18px;
            /* width: 100%; */
            margin-top: 0;
            margin-bottom: 0;
            opacity: 100%;
            transition: 1s;
            /* position: absolute; Asegura que el <hr> no afecte el flujo del layout */
            /* left: 0; Alineación horizontal */
            /* right: 0; Alineación horizontal */
      }
</style>
