import Home from "../pages/Home.svelte";
import About from "../pages/About.svelte";
import Invoices from "../pages/Invoices.svelte";
import NotFound from "../pages/NotFound.svelte";
import Login from "../pages/FullLogin.svelte";
import Emails from "../pages/Emails.svelte";
import ChangePassword from "../pages/ChangePassword.svelte";
import { writable, derived } from "svelte/store";
import { push } from "svelte-spa-router";
// import { push } from "svelte-routing";

// 1 - DEFINICIÓN DE PAGINAS:
let HOME_PAGE = "/";
let LOGIN_PAGE = "/login";
let INVOICES_PAGE = "/invoices";
let TURNO_CONFIRMADO_PAGE = "/turno-confirmado";
let AGENDA_PAGE = "/agenda";
export let CHANGE_PASSWORD_PAGE = "/change-password";
let NOT_FOUND_PAGE = "*";

// 2 - EXPORTO UN ARRAY DE RUTAS HUMAN PARA EL BANNER CON MENU ITEMS, LEJIBLES:
export const RUTAS_HUMAN = [
      { path: "/", name: "Inicio" },
      { path: "/invoices", name: "Facturas" },
      { path: "/emails", name: "Emails" },
      // { path: "/login", name: "Login" },
      // { path: "/about", name: "About" },
];

// 3 - DEFINICIÓN DEL ARR ROUTES:
export const RUTAS_COMPONENTES = {
      "/": Home,
      "/about": About,
      "/invoices": Invoices,
      "/emails": Emails,
      "/login": Login,
      "/change-password": ChangePassword,
      "*": NotFound,
};

// 11 - NAVEGACION DESDE SOLO UN SITIO:
export function irA(rutaNueva) {
      console.log("NAVEGANDO A: " + rutaNueva);
      push(rutaNueva);
}

export const PAGES_STORE = writable(RUTAS_COMPONENTES);
