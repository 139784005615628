<div class="cont-barra-progreso col-12 {clases}">
      <div class="progress-bar" style="width:100%;height: {h}">
            <div
                  class="progress flex-center"
                  style="width: {getPercentajeAlways(
                        porcentaje
                  )}; height: {h};background-color: {color};"
            >
                  {#if showPercentaje}
                        <span class="" style="color:{textColor}">
                              {porcentaje + "%"}
                        </span>
                  {/if}
            </div>
      </div>
</div>

<script>
      export let porcentaje = 10; // Valor inicial para la barra de progreso (en porcentaje)
      export let h = "14px";
      export let color = "#7f56da";
      export let clases = "";
      export let textColor = "white";
      export let showPercentaje = true;
      $: porcentaje = Math.max(0, Math.min(porcentaje, 100));

      function getPercentajeAlways(per) {
            // Verifica si 'per' ya tiene el símbolo '%', si es así, retorna el valor tal cual
            if (typeof per === "string" && per.includes("%")) {
                  return per;
            }
            // Si 'per' no tiene el '%', lo agrega
            return per + "%";
      }
</script>

<style>
      /* Contenedor de la barra de progreso */
      .progress-bar {
            width: 100%;
            height: 1.5rem;
            background-color: #e9ecef;
            border-radius: 6rem;
            overflow: hidden;
      }

      /* Barra de progreso con animación y rayas */
      .progress {
            /* #007bff */
            height: 100%;
            background-image: linear-gradient(
                  45deg,
                  rgba(255, 255, 255, 0.15) 25%,
                  transparent 25%,
                  transparent 50%,
                  rgba(255, 255, 255, 0.15) 50%,
                  rgba(255, 255, 255, 0.15) 75%,
                  transparent 75%,
                  transparent
            );
            border-radius: 6rem;
            background-size: 1rem 1rem; /* Tamaño de las rayas */
            animation: progressAnimation 1s linear infinite; /* Animación */
            transition: width 0.3s ease; /* Transición suave del ancho */
      }

      /* Animación para las rayas */
      @keyframes progressAnimation {
            from {
                  background-position: 1rem 0;
            }
            to {
                  background-position: 0 0;
            }
      }
</style>
