<Pagina></Pagina>

<script>
      import { GATEWAY } from "../stores/gateway-store";
      import { onMount } from "svelte";
      import Pagina from "../componentes/formato/PaginaBase.svelte";
      import { irA } from "../utils/ROUTING";

      onMount(() => {
            // Verifica si el usuario está autenticado o alguna condición
            const isAuthenticated = false; // Cambia esto según tu lógica

            if (!isAuthenticated) {
                  irA("/login"); // Redirige al login
            }
      });
</script>
