import { writable } from "svelte/store";

// El store manejará una notificación visible
export const notification = writable({ message: "", icon: "", color: "", visible: false });

// Función para agregar una nueva notificación
export function addNotification(message, icon = "check", color = "#4caf50") {
      notification.set({ message, icon, color, visible: true });

      // Desaparecer la notificación después de 4 segundos
      setTimeout(() => {
            notification.update((state) => ({ ...state, visible: false }));
      }, 4000); // Duración de la notificación
}
