<script>
      import BotonNico from "./BotonNico.svelte";
      import Img from "./Img.svelte";

      export let arrHeaders = []; // Array de encabezados, cada encabezado tiene 'nombre' y 'key'
      export let arrDatos = []; // Array de datos en formato JSON
      export let clases = "";
      export let br = "6px";
</script>

<table class="tabla-generica {clases}" style="border-radius: {br}; border-spacing: 0;">
      <thead style="border-radius:{br};">
            {#each arrHeaders as header}
                  <th>{header.nombre}</th>
            {/each}
      </thead>
      <tbody style="border-radius:{br};">
            {#each arrDatos as dato}
                  <tr>
                        {#each arrHeaders as header}
                              <td class="poppins grey semi-bold">
                                    {#if typeof dato[header.key] === "object"}
                                          <!-- Renderiza basado en el tipo especificado dentro del objeto -->
                                          {#if dato[header.key].tipo === "imagen" || dato[header.key].tipo === "img" || dato[header.key].tipo === "img-break"}
                                                <div
                                                      class="flex flex-center-v
                                                      {dato[header.key].clases} 
                                                      {dato[header.key].accion ? 'pointer' : ''}"
                                                      on:click={dato[header.key].accion}
                                                      on:keypress={dato[header.key].accion}
                                                >
                                                      <!-- {dato[header.key].accion} -->
                                                      {#if dato[header.key].img}
                                                            <div
                                                                  class="espaciador flex flex-center-h flex-center-v"
                                                                  style="padding-right: 8px;"
                                                            >
                                                                  <Img
                                                                        src={dato[header.key].img}
                                                                        h="40px"
                                                                        w="40px"
                                                                        br="50%"
                                                                  />
                                                                  {#if dato[header.key].tipo === "img-break"}
                                                                        <div class="col-12 pt-2">
                                                                              <span class="pl-3"
                                                                                    >{@html dato[
                                                                                          header.key
                                                                                    ].valor}</span
                                                                              >
                                                                        </div>
                                                                  {:else}
                                                                        <span class="pl-3"
                                                                              >{@html dato[
                                                                                    header.key
                                                                              ].valor}</span
                                                                        >
                                                                  {/if}
                                                            </div>
                                                      {:else}
                                                            <span
                                                                  >{@html dato[header.key].valor}
                                                            </span>
                                                      {/if}
                                                </div>
                                          {:else if dato[header.key].tipo === "boton"}
                                                <BotonNico
                                                      fnClick={dato[header.key].accion}
                                                      bgColor={dato[header.key].color}
                                                      colorIcono="white"
                                                      icono={dato[header.key].icono}
                                                      texto={dato[header.key].valor}
                                                />
                                          {:else if dato[header.key].tipo === "boton-outline"}
                                                <BotonNico
                                                      outline="true"
                                                      fnClick={dato[header.key].accion}
                                                      bgColor={dato[header.key].color}
                                                      icono={dato[header.key].icono}
                                                      texto={dato[header.key].valor}
                                                />
                                          {:else}
                                                <!-- Si no se especifica tipo, muestra como texto -->
                                                <div
                                                      class="soy-only-texto {dato[header.key]
                                                            .clases} "
                                                >
                                                      {@html dato[header.key].valor}
                                                </div>
                                          {/if}
                                    {:else}
                                          <!-- Si es un valor simple, lo muestra directamente -->
                                          {@html dato[header.key]}
                                    {/if}
                              </td>
                        {/each}
                  </tr>
            {/each}
      </tbody>
</table>

<style>
      /* Estilos de la tabla */
      .tabla-generica {
            width: 100%;
            border-collapse: separate;
            border: 1px solid #ddd;
      }
      thead {
            border-bottom: 1px solid #ddd;
      }
      th,
      td {
            padding: 8px;
            text-align: center;
            border-bottom: 1px solid #ddd;
      }
      th:first-child,
      td:first-child {
            text-align: left;
      }
      button {
            padding: 5px 10px;
            font-size: 0.9em;
      }
      .black-span {
            color: red !important;
      }
</style>
