<div
      class="cont-header-violeta col-12 bsv flex {clases}"
      style="border-radius: 10px 10px 10px 10px; border-bottom: solid 4px #7f56da"
>
      <div class="real-texto-header-violeta poppins violet bold p-2">
            {#if icono.length > 0}
                  <IconoNico name="spinner" color="#7f56da" clases="pr-3" icono="copy" />
            {/if}
            {texto}
      </div>
</div>

<script>
      export let texto = "Header Violeta Texto";
      export let icono = "";
      export let clases = "";
      import IconoNico from "./IconoNico.svelte";
</script>

<style>
      .real-texto-header-violeta {
            padding-left: 25px !important;
      }
</style>
