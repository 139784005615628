<script>
      import {
            OPERADOR_LOGEADO_STORE,
            comprobarOperadorLogeado,
            exit,
      } from "../../stores/operador-logeado-store.js";
      import { irA, RUTAS_COMPONENTES } from "../../utils/ROUTING.js";
      import IconoNico from "../basics/IconoNico.svelte";
      import Img from "../basics/Img.svelte";
      import ImgSimple from "../basics/ImgSimple.svelte";
      import { onDestroy } from "svelte";
      import { CHANGE_PASSWORD_PAGE } from "../../utils/ROUTING.js";

      let operadorLogeado;
      let showOptions = false;
      $: operadorLogeado;

      // 1 - ME SUSCRIBO AL OPERADOR LOGEADO STORE PARA ENTERARME SI ESTA LOGEADO QUIEN ES Y SI SE DESLOGEO O ALGO:
      const unsubscribe = OPERADOR_LOGEADO_STORE.subscribe((store) => {
            operadorLogeado = store.operadorLogeado;
      });

      // 3 - ME DESUSCRIBO SI MUERE EL COMPONENTE PARA NO GASTAR RECURSOS:
      onDestroy(() => {
            unsubscribe();
      });

      function cambiarPass() {
            irA(CHANGE_PASSWORD_PAGE);
      }
      function mostrarOpcionesLogin() {
            showOptions = !showOptions;
      }
</script>

<div
      class="cont-cuadrado-operador-logeado-banner col-12 flex pointer"
      on:click={mostrarOpcionesLogin}
      on:keypress={mostrarOpcionesLogin}
>
      {#if operadorLogeado != null}
            <div class="centrador-img-operador-logeado flex-center-h">
                  <Img src={operadorLogeado?.fotoPerfil?.full} w="30px" h="30px" />
            </div>
            <div class=" pl-3 flex-center-v">
                  {operadorLogeado?.email}
                  {#if showOptions}
                        <IconoNico name="chevron-up" clase="pointer" />
                  {:else}
                        <IconoNico name="chevron-down" clase="pointer" />
                  {/if}
            </div>
      {:else}
            <IconoNico name="user" clase="pointer" />
      {/if}
      <!-- OP:{JSON.stringify(operadorLogeado)} -->
</div>

{#if showOptions}
      <div class="cont-opciones-operador-logeado col-3">
            <div class="col-12 opcion pointer" on:click={cambiarPass} on:keypress={cambiarPass}>
                  <IconoNico name="password" clase="pointer" color="grey" />
                  Cambiar Clave
            </div>
            <hr />
            <div class="col-12 opcion pointer" on:click={exit} on:keypress={exit}>
                  <IconoNico name="exit" clase="pointer" color="grey" />
                  Cerrar Sesion
            </div>
      </div>
{/if}

<style>
      * {
            /* font-family: "Poppins"; */
      }
      .opcion:hover {
            font-weight: bold;
      }
      .cont-opciones-operador-logeado {
            position: absolute;
            top: 52px;
            right: 10px;
            background-color: white;
            border-left: solid 1px grey;
            border-bottom: solid 1px grey;
            border-right: solid 1px grey;
            padding: 12px;
            border-radius: 0px 0px 6px 6px;
            /* background-color: black; */
            color: grey;
            z-index: 99;
      }
</style>
