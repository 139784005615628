<div class="cont-login">
      <div class="centrador-vertical login flex-center-v">
            <div class="cont-real-login col-12">
                  <Input type="email" label="Email" bind:value={usr} fnEnter={waitingForLogin} />
                  <Input
                        type="password"
                        label="Clave"
                        bind:value={pass}
                        fnEnter={waitingForLogin}
                  />
                  <BotonNico
                        fnClick={waitingForLogin}
                        texto="Ingresar"
                        textoCargando="Ingresando.."
                        bind:cargando={ingresando}
                  />
            </div>
      </div>
</div>

<script>
      import BotonNico from "./BotonNico.svelte";
      import Input from "./Input.svelte";
      import { OPERADOR_LOGEADO_STORE, logearse } from "../../stores/operador-logeado-store";
      import { PAGES_STORE, irA } from "../../utils/ROUTING.js";

      // Definimos variables reactivas
      export let usr = "";
      export let pass = "";
      let recordarPass = false;
      let ingresando = false;

      // HANDLE LOGIN CLICK:
      async function waitingForLogin() {
            ingresando = true;
            await logearse(usr, pass);

            ingresando = false;
      }
</script>

<style>
      .centrador-vertical {
            height: 99vh;
      }
</style>
