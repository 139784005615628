<div class="cont-footer col-12" style="background-color:{color};height:{h}; color:{textColor}">
      {#if operadorLogeado}
            OP LOGEADO: {JSON.stringify(operadorLogeado)}
      {:else}
            Footer: No hay operador logeado.
      {/if}
      <div class="col-12 flex">
            <!-- <div class="col-4">col izq footer</div>
            <div class="col-4">col mid footer</div>
            <div class="col-4">col der footer</div> -->
      </div>
</div>

<script>
      import { getContext } from "svelte";

      // const operadorLogeado = getContext("operadorLogeado");
      export let operadorLogeado;
      export let color = "#8E44AD";
      export let textColor = "white";
      export let h = "250px";
      // let perro = getContext("perro");
      $: operadorLogeado;
</script>

<style>
      .cont-footer {
            margin-top: 25px;
      }
</style>
