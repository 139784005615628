<div class="cont-contenido-modal">
      <Input label="Mes" bind:value={mesFactura} />
      <Input label="Año" bind:value={yearFactura} />
      <ComboBox arrOpciones={["asdf", "jsk", "kasñaskd"]} />
</div>

<script>
      import { BOTONERA_MODAL_STORE, proceed, back } from "../../stores/modal-store";
      import { onMount } from "svelte";
      import ComboBox from "../basics/ComboBox.svelte";

      let mesFactura;
      let yearFactura;
      onMount(() => {
            mesFactura = new Date().getMonth();
            yearFactura = new Date().getFullYear();

            // Asignar las funciones a los botones cuando el modal se monte
            BOTONERA_MODAL_STORE.set({
                  proceed: enviarFactura, // Asigna la función para el botón "Proceed"
                  back: back, // Asigna la función para el botón "Back"
            });
      });

      import Input from "../basics/Input.svelte";
      async function enviarFactura() {
            const url = "http://localhost:8999/factura/";
            const data = {
                  mesFactura: mesFactura,
                  yearFactura: yearFactura,
                  fkEmpresa: 1,
            };

            try {
                  const response = await fetch(url, {
                        method: "POST",
                        headers: {
                              accept: "*/*",
                              "Content-Type": "application/json",
                        },
                        body: JSON.stringify(data),
                  });

                  // Manejo de la respuesta
                  if (response.ok) {
                        const jsonResponse = await response.json();
                        console.log("Factura enviada:", jsonResponse);
                  } else {
                        console.error("Error al enviar la factura:", response.statusText);
                  }
            } catch (error) {
                  console.error("Error en la solicitud:", error);
            }
      }
</script>

<style>
</style>
