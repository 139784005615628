// operadorStore.js
import { writable } from "svelte/store";
import { GATEWAY } from "./gateway-store.js";
import { get } from "svelte/store";
import { irA } from "../utils/ROUTING.js";

// Inicializa el estado del operador logeado
export const OPERADOR_LOGEADO_STORE = writable({
      operadorLogeado: null,
});

// Función para logearse
export async function logearse(usr, pass) {
      const gatewayUrl = get(GATEWAY).url;
      console.log("LOGEANDOSE...");
      try {
            const response = await fetch(
                  `${gatewayUrl}operador/logearse?email=${usr}&pass=${pass}`,
                  {
                        method: "POST",
                        headers: {
                              "Content-Type": "application/json",
                        },
                  }
            );

            // Suponemos que la respuesta es un JWT directamente
            const jwt = await response.text();

            if (response.ok) {
                  console.log("Login exitoso!");
                  console.log("JWT recibido:", jwt);
                  localStorage.setItem("jwt", jwt); // Almacena el JWT en localStorage

                  // Redirige a la página de facturas
                  irA("/invoices");
            } else {
                  alert("NO PUDISTE LOGEARTE");
            }
      } catch (error) {
            console.error("Error:", error);
      }
}

// Función para comprobar si el operador está logeado
export async function comprobarOperadorLogeado() {
      console.log("COMPROBANDO OPERADOR LOGEADO:");
      const gatewayUrl = get(GATEWAY).url;
      const jwt = localStorage.getItem("jwt");
      let opLogeadoAUX = null;

      if (!jwt || jwt.length <= 4) {
            console.log("No JWT found in local storage.");
            // Resetea el estado del operador logeado
            opLogeadoAUX = null;

            // OPERADOR_LOGEADO_STORE.update((store) => ({
            //       ...store,
            //       operadorLogeado: opLogeadoAUX,
            // }));
      } else {
            console.log("EN LOCALSTORAGE TENÍA UN JWT GUARDADO:", jwt);

            try {
                  const response = await fetch(`${gatewayUrl}operador/dameOperadorLogeado`, {
                        method: "POST",
                        headers: {
                              accept: "*/*",
                              Authorization: `Bearer ${jwt}`,
                              "Content-Type": "application/json",
                        },
                        body: JSON.stringify({}),
                  });

                  if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                  }

                  const data = await response.json();

                  // Actualiza el estado del operador logeado
                  opLogeadoAUX = data;
            } catch (error) {
                  irA("/login");
                  console.error("Error fetching operador logeado:", error);
            }

            OPERADOR_LOGEADO_STORE.update((store) => ({
                  ...store,
                  operadorLogeado: opLogeadoAUX,
            }));
            console.log("RTA OPERADOR LOGEADO: " + JSON.stringify(opLogeadoAUX));
      }
}

// Función para salir del sistema
export function exit() {
      let jwt = localStorage.getItem("jwt");
      console.log("EL VALOR DEL JWT EN LOCAL STORAGE AL MOMENTO ES:", jwt);
      localStorage.setItem("jwt", null);
      jwt = localStorage.getItem("jwt");

      // Resetea el estado
      OPERADOR_LOGEADO_STORE.update((store) => ({
            ...store,
            operadorLogeado: null,
      }));

      console.log("EL VALOR DEL JWT EN LOCAL STORAGE DESPUES DE SALIR ES:", jwt);
      irA("/login"); // Redirige al login
}
