import { writable } from "svelte/store";

// Guardamos la función a ejecutar en el store
export const BOTONERA_MODAL_STORE = writable({
      proceed: null,
      back: null,
});

// Funciones que se pueden asignar
export function proceed() {
      console.log("Ejecutando función 1");
}

export function back() {
      console.log("Ejecutando función 2");
}
