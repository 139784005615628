<script>
      import { isNumeric } from "../../utils/utils.js";
      export let src = "imgs/no-image.png";
      export let h = "200px";
      export let w = "200px";
      export let br = "16px";
      export let debug = false;
      export let clases = "";

      const addPx = (value) => {
            let rta = value;

            if (debug) {
                  console.log(`IMG - VALOR RECIBIDO: ${value}`); // Log adicional para verificar el valor devuelto
            }

            if (isNumeric(value) && value > 0) {
                  rta = `${value}px`;
            }

            if (debug) {
                  console.log(`IMG - VALOR RESULTADO:: ${rta}`); // Log adicional para verificar el valor devuelto
            }

            return rta;
            // return  ? `${value}px` : value;
      };
</script>

<div
      class="img img-bg {clases}"
      style="background-image: url('{src}'); height: {addPx(h)};width:{addPx(
            w
      )};border-radius: {addPx(br)};;"
></div>

<style>
      .img {
            /* border: solid 1px black; */

            background-position: center center;
      }
</style>
