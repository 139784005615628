<div class="cont-combobox-mas-label col-12 bsr" on:focusout={blurCombo}>
      <!-- on:focusin={() => (comboAbierto = true)} on:focusout={handleBlur} -->
      <label
            class="label-input pointer {comboAbierto || selected != null ? 'floated' : ''}"
            for="real-combo"
            on:click={abrirCombo}
            on:keypress={abrirCombo}
      >
            {label}
      </label>

      <div class="cont-combo-visible-sin-opciones pointer flex pt-3">
            <div class="w100-50" style="width: calc(100% - 50px);">
                  <!-- mt-3  -->
                  <input
                        type="text"
                        id="real-combo"
                        class="el-f-combo-box input col-12 form-control pointer"
                        on:click={abrirCombo}
                        on:keypress={abrirCombo}
                        bind:value={selectedNOTNull}
                        on:keydown={manejarTeclas}
                  />
            </div>
            <div
                  class="cont-flecha-combo-box flex-center-h flex-center-v"
                  on:click={unselect}
                  on:keypress={unselect}
            >
                  <IconoNico name="cross" color="grey" />
            </div>
            <!-- POS FLECHAS:{posFlechas} -->
            <!-- <div class="search-input mt-3 mb-3">
                        <input
                              type="text"
                              class="col-12 form-control mt-3"
                              bind:value={busqueda}
                              id="busqueda-input"
                              placeholder="Puedes buscar aquí.."
                        />
                  </div> -->
      </div>
      {#if comboAbierto}
            <div class="cont-opciones-dentro-combo">
                  <div class="cont-opciones">
                        {#each arrOpciones.filter((opcion) => opcion.nombre
                                    .toLowerCase()
                                    .includes(busqueda.toLowerCase())) as opcionLoop, index}
                              <div
                                    class="opcion-item {index === posFlechas
                                          ? 'opcion-item-selected'
                                          : ''}"
                                    on:click={() => seleccionarOpcion(index)}
                                    on:keypress={() => seleccionarOpcion(index)}
                              >
                                    <!-- on:click={seleccionarOpcion(opcionLoop)} -->
                                    <!-- on:keypress={seleccionarOpcion(opcionLoop)} -->
                                    {opcionLoop.nombre}
                              </div>
                        {/each}
                  </div>
            </div>
      {/if}
</div>

<!-- <h3>OPCION SELECTED: {JSON.stringify(selected)}</h3> -->

<script>
      import IconoNico from "./IconoNico.svelte";
      export let label = "Selecciona una opcion";
      export let busqueda = "";
      export let selected = null;
      export let comboAbierto = false;
      export let arrOpciones = [
            { id: 1, nombre: "Opcion 1" },
            { id: 2, nombre: "Opcion 2" },
            { id: 3, nombre: "Opcion 3" },
      ];
      let isFocused = false;
      $: selectedNOTNull = selected ? selected.nombre : "";
      // $: labelFloated = isFocused || selectedNOTNull.length > 0;
      let posFlechas = 0;
      function abrirCombo() {
            comboAbierto = true;
      }
      function toogleCombo() {
            comboAbierto = !comboAbierto;
      }

      // export function seleccionarOpcion(opcionSelected) {
      //       if (opcionSelected != null) {
      //             if (selected != null) {
      //                   if (selected.id == opcionSelected.id) {
      //                         selected = null;
      //                   } else {
      //                         selected = opcionSelected;
      //                   }
      //             } else {
      //                   selected = opcionSelected;
      //             }
      //       } else {
      //             selected = opcionSelected;
      //       }

      //       setTimeout(() => {
      //             abrirCombo();
      //       }, 50);
      // }
      export function seleccionarOpcion(index) {
            posFlechas = index;
            // Filtra las opciones visibles basándose en el valor de búsqueda
            const opcionesFiltradas = arrOpciones.filter((opcion) =>
                  opcion.nombre.toLowerCase().includes(busqueda.toLowerCase())
            );

            // Si el índice es válido, selecciona el elemento correspondiente
            if (index >= 0 && index < opcionesFiltradas.length) {
                  const opcionSelected = opcionesFiltradas[index];

                  if (selected != null && selected.id === opcionSelected.id) {
                        selected = null; // Deseleccionar si se vuelve a hacer clic en el mismo
                  } else {
                        selected = opcionSelected;
                  }
            } else {
                  selected = null; // Deselección en caso de índice inválido
            }

            // Cierra el combo después de un breve retraso
            setTimeout(() => {
                  blurCombo();
            }, 50);
      }
      export function unselect() {
            posFlechas = -1;
            selected = null;
            comboAbierto = false;
      }

      function blurCombo(event) {
            // Verifica si el nuevo foco (relatedTarget) está dentro del contenedor
            const currentTarget = event.currentTarget;
            const newFocus = event.relatedTarget;

            if (currentTarget.contains(newFocus)) {
                  // Si el foco se queda dentro del contenedor, no cerrar
                  return;
            }

            comboAbierto = false;
      }

      // function blurCombo() {
      //       // const related = event.target.id;

      //       // Log para depuración
      //       // console.log("Elemento relacionado (relatedTarget):", related);

      //       // Verificar si el foco se movió a un elemento fuera del contenedor
      //       // if (!related || !event.currentTarget.contains(related)) {
      //       //       // Checar si el evento ocurrió en una opción dentro del contenedor
      //       //       const clickedInside = event.target.closest(".cont-combobox");
      //       //       if (clickedInside) {
      //       //             console.log("El clic ocurrió dentro del contenedor. No cerrar el combo.");
      //       //             return;
      //       //       }

      //       console.log("El foco se movió fuera del contenedor. Cerrando el combo.");
      //       comboAbierto = false; // Cerrar el combo si el foco realmente salió
      //       // }
      // }
      function manejarTeclas(event) {
            if (comboAbierto) {
                  const maxIndex =
                        arrOpciones.filter((opcion) =>
                              opcion.nombre.toLowerCase().includes(busqueda.toLowerCase())
                        ).length - 1;

                  if (event.key === "ArrowDown") {
                        posFlechas = Math.min(posFlechas + 1, maxIndex);
                        event.preventDefault();
                  } else if (event.key === "ArrowUp") {
                        posFlechas = Math.max(posFlechas - 1, 0);
                        event.preventDefault();
                  } else if (event.key === "Enter") {
                        const opcionesFiltradas = arrOpciones.filter((opcion) =>
                              opcion.nombre.toLowerCase().includes(busqueda.toLowerCase())
                        );
                        seleccionarOpcion(posFlechas);
                  } else if (event.key === "Escape") {
                        comboAbierto = false; // Cierra el combo si se presiona Escape
                        event.preventDefault();
                  }
            }
      }
</script>

<style>
      .cont-combobox-mas-label {
            /* border: solid 1px grey; */
            padding: 15px;
            /* border-radius: 50px; */
      }
      .cont-combo-visible-sin-opciones {
            border-radius: 50px;
            /* background-color: blue; */
      }
      .cont-real-combo {
            /* border: solid 1px grey; */
            /* border-radius: 50px; */
      }
      .opcion-item {
            border-bottom: solid 1px grey;
            padding: 15px;
            cursor: pointer;
      }
      .opcion-item-selected {
            background-color: #7f56da;
            color: white;
      }
      .el-f-combo-box {
            border-radius: 50px;
      }
      .label-input {
            position: relative;
            left: 20px;
            top: 58px;
            font-size: 16px;
            color: #5f5f5f;
            transition:
                  top 0.2s ease,
                  font-size 0.2s ease,
                  color 0.2s ease;
            cursor: text;
      }

      .label-input.floated {
            top: 25px;
            left: 30px;
            font-size: 14px;
            color: #7f56da;
            background-color: white;
            padding: 0 5px;
            border-radius: 4px;
      }

      .input {
            border: 1px solid #c3c3c3;
            border-radius: 4px;
            font-size: 16px;
            outline: none;
            transition: border-color 0.2s;
            padding: 16px 12px;
            width: 100%;
            background-color: transparent;
      }

      .input:focus {
            border-color: #7f56da;
      }
</style>
