<span
      class="cont-svg centrador {clases}  flex flex-center-h flex-center-v"
      style="padding-left:{pl} !important ; padding-right: {pr} !important"
      on:click={fnClick}
      on:keypress={fnClick}
>
      {#if ready}
            <svg
                  fill="none"
                  class=""
                  id={randomID}
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 128 128"
                  style="enable-background:new 0 0 128 128; width:{w}; height: {h}"
            ></svg>
      {:else}
            <!-- SVG spinner de carga -->
            <svg
                  class="rotate"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 128 128"
                  style="enable-background:new 0 0 128 128; width:{w}; height: {h}"
            >
                  <path
                        style="fill: none; stroke: {color}; stroke-width: 12; stroke-linecap: round; stroke-miterlimit: 10;"
                        d="M75.38,11.39c24.13,5.18,42.22,26.64,42.22,52.31c0,29.55-23.96,53.51-53.51,53.51S10.58,93.26,10.58,63.71
          c0-24.42,16.36-45.02,38.71-51.43"
                  />
            </svg>
      {/if}
</span>

<script>
      import { onMount } from "svelte";
      export let name = "cross";
      export let w = "20px";
      export let h = "20px";
      export let color = "white";
      export let strokeW = 4;
      export let spin = false;
      export let clases = "";
      export let pr = "3px";
      export let pl = "3px";
      export let fnClick = () => {
            console.log("FUNCION NO ASOCIADA AL ICONO - DEBO CARGARLA POR FUERA DEL COMPONENTE:");
      };
      let ready = false;

      let randomID = generateRandomID();

      // Función para generar estilos inline
      function getInlineStyles(color, strokeWidth) {
            return `
      fill: transparent;
      stroke: ${color};
      stroke-width: ${strokeWidth};
      stroke-linecap: round;
    `;
      }

      // Función para generar un ID aleatorio único para cada icono
      function generateRandomID() {
            return "my-svg-" + new Date().getTime() + Math.random().toString(36).substr(2, 12);
      }

      let contenidoSVG = "";
      onMount(async () => {
            try {
                  // 1 - TRAER EL SVG DE REMOTE:
                  const url = `http://viewdevs.com.ar:8081/ICONS/${name}.svg`;
                  const response = await fetch(url);
                  if (response.ok) {
                        ready = true;
                        // 2 - OBTENGO EL TEXTO DEL SVG REMOTO
                        let remoteSVG = await response.text();

                        // 3 - Extraer el contenido entre <svg> y </svg>
                        const allContenidoSVG = remoteSVG.match(/<svg[^>]*>([\s\S]*?)<\/svg>/);

                        if (allContenidoSVG && allContenidoSVG[1]) {
                              // 4 - Limpiar el contenido del SVG (quitar <style>)
                              let svgContentWithoutStyle = allContenidoSVG[1].replace(
                                    /<style[\s\S]*?<\/style>/g,
                                    ""
                              );

                              // 5 - Generar los estilos inline para cada elemento
                              let inlineStyle = getInlineStyles(color, strokeW);

                              // 6 - Reemplazar las clases por estilos inline en el contenido del SVG
                              svgContentWithoutStyle = svgContentWithoutStyle.replace(
                                    /class="st[0-9]"/g,
                                    (match) => {
                                          return `style="${inlineStyle}"`; // Cambiar cada "class" por "style"
                                    }
                              );

                              // 7 - Insertar el SVG final con estilos inline
                              const svgFinal = svgContentWithoutStyle; // Los estilos ya están aplicados inline, no necesitamos <style> ahora.

                              // 8 - Insertar el SVG final en el contenedor correspondiente
                              const elementoSVGHTML = document.getElementById(randomID);
                              if (elementoSVGHTML && svgFinal) {
                                    elementoSVGHTML.innerHTML = svgFinal;
                              }
                        } else {
                              throw new Error("No se encontró contenido SVG válido en el archivo.");
                        }
                  } else {
                        throw new Error("Icono " + name + " no encontrado");
                  }
            } catch (e) {
                  console.error("No se pudo cargar el icono:", e);
            }
      });
</script>

<style>
      /* .cont-svg {
            padding: 0px;
            margin: 0px;
            display: flex;
            align-items: center;
            width: fit-content;
      } */
      .cont-svg {
            padding: 0px;
            margin: 0px;
            /* vertical-align: baseline; */
            /* box-sizing: border-box; */
            display: inline-flex; /* Change here */

            /* text-align: center; Centra el contenido horizontalmente */
            vertical-align: middle;
            width: fit-content;
      }
      .cont-svg.inline {
            display: inline-flex; /* Hace que los hijos se alineen en línea */
            flex-direction: row; /* Asegura que los elementos se alineen en fila */
      }
      svg {
            /* padding: 0px; */
            /* margin: 0px; */
      }
      .rotate {
            animation: spin 1.5s linear infinite;
      }

      @keyframes spin {
            from {
                  transform: rotate(0deg);
            }
            to {
                  transform: rotate(360deg);
            }
      }
</style>
