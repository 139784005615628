// store.js
import { writable } from "svelte/store";

export const screenSize = writable({
	xl: false,
	lg: false,
	md: false,
	sm: false,
	xs: false,
	SIZEX: "XS",
});
