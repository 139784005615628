<script>
      import { onMount } from "svelte";
      import { isNumeric } from "../../utils/utils.js";
      export let src = "imgs/no-image.png";
      export let h = "200px";
      export let w = "200px";
      export let br = "16px";
      export let p = "2px";
      export let debug = false;
      export let clases = "";
      let altoFinalImgInterna = "100%";
      let paddingPorDos = 0;

      onMount(() => {
            // Convierte p a string y obtiene el número.
            paddingPorDos = obtenerNumeroEntero(String(p)) * 2;
            let hAux = obtenerNumeroEntero(String(h));
            altoFinalImgInterna = hAux - paddingPorDos;
            console.log(
                  "HEIGHT : " +
                        h +
                        " - PADDING RECIBIDO: " +
                        p +
                        " - PADDING X 2:" +
                        paddingPorDos +
                        " - ALTO FINAL IMG INTERNA = " +
                        altoFinalImgInterna
            );
      });

      function obtenerNumeroEntero(cadena) {
            // Usa una expresión regular para encontrar el primer conjunto de dígitos
            const primerNumero = cadena.match(/\d+/);
            // Convierte a número y lo devuelve, o devuelve null si no hay números
            return primerNumero ? parseInt(primerNumero[0], 10) : null;
      }

      const addPx = (value) => {
            let rta = value;

            if (debug) {
                  console.log(`IMG - VALOR RECIBIDO: ${value}`); // Log adicional para verificar el valor devuelto
            }

            if (isNumeric(value) && value > 0) {
                  rta = `${value}px`;
            }

            if (debug) {
                  console.log(`IMG - VALOR RESULTADO:: ${rta}`); // Log adicional para verificar el valor devuelto
            }

            return rta;
      };
</script>

<div
      class="cont-bordecito-blanco"
      style=" height: {addPx(h)};width:{addPx(w)};  border-radius: {addPx(br)} ; padding: {addPx(
            p
      )}"
>
      <div
            class="img img-bg {clases}"
            style="background-image: url('{src}');
            height: {addPx(altoFinalImgInterna)} ;width:{addPx(w)}; border-radius: {addPx(
                  br
            )};padding:{addPx(p)}"
      >
            <!-- {altoFinalImgInterna} -->
            <!-- height:100%; width:100%" -->
      </div>
</div>

<style>
      .cont-bordecito-blanco {
            /*padding: 4px;*/
            background-color: white;
            /* border-radius: 3px; */
            overflow: hidden;
            box-shadow:
                  0 4px 6px rgba(0, 0, 0, 0.1),
                  0 1px 3px rgba(0, 0, 0, 0.06);
            /* padding: 4px; */
      }
      .img {
            /* border: solid 1px black; */

            background-position: center center;
            /* border-radius: 10px; */
      }
</style>
