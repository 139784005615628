<div class="item-archivo-temp">
      <!-- 1 - FILA 1: -->
      <div class="fila-1 col-12 flex">
            <div class="cont-img-archivo-temp" style="width: 60px;height:100%;">
                  <!-- 1 - IMAGEN ARCHIVO SUBIDO: -->
                  {#if archivoTEMP.type === "image/jpeg" || archivoTEMP.type === "image/png"}
                        <ImgBordecitoBlanco
                              src={getFileIcon(archivoTEMP)}
                              w="100%"
                              h="55px"
                              br="10px"
                        />
                  {:else}
                        <ImgBordecitoBlanco
                              src={getFileIcon(archivoTEMP)}
                              w="100%"
                              h="55px"
                              br="10px"
                        />
                  {/if}
            </div>

            <div class="cont-textos-item-archivo pl-3" style="width: calc(100% - 92px)">
                  <!-- 2 - CARTEL CARNICELERO NOMBRE:-->
                  <div class="">
                        <HCarnicero texto={archivoTEMP.name} w="100%" pt="4px" pb="4px" />
                  </div>

                  <!-- 3 - CARTEL CARNICELERO EXTENSION:-->
                  <div class="col-12">
                        <H3
                              style="padding-left:15px"
                              texto={"Extension: " + getFileExtension(archivoTEMP)}
                        />
                  </div>
            </div>
      </div>
</div>

<script>
      import H3 from "./H3.svelte";
      import HCarnicero from "./HCarnicero.svelte";
      import ImgBordecitoBlanco from "./ImgBordecitoBlanco.svelte";

      export let archivoTEMP;

      function getFileIcon(file) {
            const ext = file.name.split(".").pop().toLowerCase();
            if (["jpg", "jpeg", "png"].includes(ext)) {
                  return URL.createObjectURL(file); // Para imágenes, se muestra la imagen
            } else if (["pdf"].includes(ext)) {
                  return "imgs/pdf.png"; // Ruta al icono de PDF
            } else if (["xls", "xlsx"].includes(ext)) {
                  return "imgs/excel.png"; // Ruta al icono de Excel
            } else {
                  return "imgs/file.png"; // Ruta al icono genérico para otros archivos
            }
      }
      function getFileExtension(file) {
            return file.name.split(".").pop().toLowerCase();
      }
</script>

<style>
      .item-archivo-temp {
            margin-bottom: 15px;
            border-radius: 12px;
            background-color: #f7f8f8;
            padding: 12px;
            border: solid 1px lightgrey;
      }
      .h-extension {
            font-size: 14px;
      }
      .cont-img-archivo-temp {
            width: 30px;
            height: 30px;
      }
      /* .h-extension {
            padding-left: 15px;
            font-weight: thin;
            color: #aeafb0;
      } */
</style>
