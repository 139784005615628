<Pagina>
      <div style="padding: 25px;">
            <!-- <HeadeVioleta texto="Trayendo estado cuenta.." icono="spinner" /> -->

            <!-- 1 - SI YA CARGO EL ESTADO DE CUENTA POR FETCH:-->
            {#if estadoCuenta}
                  <h3>Facturas</h3>
                  <TablaSimple
                        arrHeaders={arrHeadersTablaFacturasEstadoCuenta}
                        arrDatos={acomodarDatosTablaFacturasEstadoCuenta(estadoCuenta)}
                  />

                  <!-- MODAL DET FACTURA:-->
                  <ModalBase
                        title={tituloModal}
                        bind:modalOpen={modalEstadoFacturaOpen}
                        ContentComponent={ContenidoModalDetFactura}
                        h="1000px"
                        w="650px"
                  />

                  <!-- MODAL PAGAR O ABONAR FACTURA: -->
                  <ModalBase
                        title="Como abonar tu Factura"
                        bind:modalOpen={modalAbonarFacturasOpen}
                        selected={facturaSelected}
                        propsSlot={{ DEBUG: DEBUG }}
                        ContentComponent={ContModalAbonarFact}
                        h="1000px"
                        w="650px"
                  />

                  <!-- MODAL ADD FACTURA ONLY ADMIN:: -->
                  <ModalBase
                        title="Crear nueva factura"
                        bind:modalOpen={modalAddFacturaNewOnlyAdmin}
                        propsSlot={{ DEBUG: DEBUG }}
                        ContentComponent={ContModalAddFacturaAdmin}
                        h="1000px"
                        w="650px"
                  />

                  <!-- MODAL ADD DET FACTURA ONLY ADMIN:: -->
                  <ModalBase
                        title="Agregar Producto a factura"
                        bind:modalOpen={modalDetFactura}
                        propsSlot={{ DEBUG: DEBUG }}
                        ContentComponent={ContModalAddDetFactura}
                        h="1000px"
                        w="650px"
                  />
            {:else}
                  <!-- 2 - SI TODAVIA NO LO PUDO TRAER EL ESTADO CUENTA POR FETCH: -->
                  <HeadeVioleta texto="Obteniendo estado cuenta.." icono="spinner" />
            {/if}
      </div>
      <div class="cont-botonera-admin col-12 bsr p-3">
            <BotoN fnClick={openModalCreateNewFactura} icono="plus" texto="Crear Nueva Factura" />
            <BotoN fnClick={openModalAddDetFactura} icono="plus" texto="Add Det Factura" />
      </div>
</Pagina>

<script>
      import Pagina from "../componentes/formato/PaginaBase.svelte";
      import { GATEWAY } from "../stores/gateway-store";
      import { onMount } from "svelte";
      import { facturaSelectedSTORE } from "../stores/factura-selected-store";
      import { tick } from "svelte";
      import { formatearOnlyFechaBonita, formateaOnlyHoraBonita } from "../utils/utils";
      import { uploadStore } from "../stores/upload-fn-multiple-files-store";
      import ContenidoModalDetFactura from "../componentes/modales/ContenidoModalDetFactura.svelte";
      import ContModalAbonarFact from "../componentes/modales/ContModalAbonarFact.svelte";
      import HeadeVioleta from "../componentes/basics/HeadeVioleta.svelte";
      import TablaSimple from "../componentes/basics/TablaSimple.svelte";
      import BotoN from "../componentes/basics/BotoN.svelte";
      import ModalBase from "../componentes/modales/ModalBase.svelte";
      import ContenidoArchivosAttached from "../componentes/modales/ContenidoArchivosAttached.svelte";
      import { comprobarOperadorLogeado } from "../stores/operador-logeado-store";
      import ContModalAddFacturaAdmin from "../componentes/modales/ContModalAddFacturaAdmin.svelte";
      import ContModalAddDetFactura from "../componentes/modales/ContModalAddDetFactura.svelte";

      let modalAbonarFacturasOpen = false;
      let modalEstadoFacturaOpen = false;
      let modalArchivosAttachedOpen = false;
      let modalAddFacturaNewOnlyAdmin = false;
      let modalDetFactura = false;
      let tituloModal = "";
      let estadoCuenta;
      let cargandoEstadoCuenta = true;
      let facturaSelected = null;
      let DEBUG = false;

      onMount(() => {
            comprobarOperadorLogeado();
            getEstadoCuenta();
      });

      function openModalPagoFacturas(idFacturaSelected) {
            console.log("OPEN MODAL PAGO FACTURA:" + idFacturaSelected + "!");

            estadoCuenta.arrFacturas.forEach((facturaLoop) => {
                  if (facturaLoop.id == idFacturaSelected) {
                        setearFacturaSelected(facturaLoop);
                  }
            });
            tituloModal = "Informacion para Abonar la Factura " + idFacturaSelected;
            modalAbonarFacturasOpen = true;
      }
      function openModalEstadoFacturas(idFacturaSelected) {
            console.log("OPEN MODAL ESTADO FACTURA:" + idFacturaSelected);
            modalEstadoFacturaOpen = true;

            estadoCuenta.arrFacturas.forEach((facturaLoop) => {
                  if (facturaLoop.id == idFacturaSelected) {
                        // facturaSelected = facturaLoop;
                        setearFacturaSelected(facturaLoop);
                  }
            });
            // tituloModal = "Informacion para Abonar la Factura "+  idFacturaSelected;
      }
      function openModalCreateNewFactura() {
            modalAddFacturaNewOnlyAdmin = true;
      }
      function openModalAddDetFactura() {
            modalDetFactura = true;
      }
      function openModalArchivosAttached(idFacturaSelected) {
            console.log("OPEN MODAL ARCHIVOS ATTACHED" + idFacturaSelected);
            modalArchivosAttachedOpen = true;

            estadoCuenta.arrFacturas.forEach((facturaLoop) => {
                  if (facturaLoop.id == idFacturaSelected) {
                        // facturaSelected = facturaLoop;
                        setearFacturaSelected(facturaLoop);
                  }
            });
            tituloModal = "Archivos adjuntos a la Factura [" + idFacturaSelected + "]";
      }
      function downloadFile(urlFile) {
            console.log("DOWLOADING FILE: " + urlFile);
            window.open(urlFile, "_blank"); // Abre en una nueva pestaña
            // modalEstadoFacturaOpen = true;
      }
      function jsonear(obj) {
            return JSON.stringify(obj);
      }

      async function getEstadoCuenta() {
            console.log("GETTING ESTADO CUENTA");
            try {
                  const response = await fetch($GATEWAY.url + "factura/estado/actual");

                  if (!response.ok) {
                        throw new Error("Error al obtener el estado de cuenta");
                  }

                  estadoCuenta = await response.json();
                  cargandoEstadoCuenta = false;

                  if (estadoCuenta != null) {
                        estadoCuenta.arrFacturas.forEach((facturaLoop) => {
                              let aux = {
                                    id: facturaLoop?.id,
                                    titulo: facturaLoop?.nombreCalculado,
                                    img: facturaLoop?.empresa?.logo?.full,
                                    estado: facturaLoop?.ultimoCambioEstadoFactura
                                          ?.nombreEstadoActual,
                                    importe: facturaLoop?.calcularImporteFinal,
                              };
                              // arrParseado.push(aux);
                        });
                        let facturaSelectedAux = estadoCuenta.arrFacturas[0];
                        //TODO: REMOVE:
                        // openModalEstadoFacturas(estadoCuenta.arrFacturas[0].id);
                        // openModalPagoFacturas(estadoCuenta.arrFacturas[0].id);
                        openModalCreateNewFactura();
                  }
            } catch (error) {
                  console.error("Hubo un problema con la solicitud:", error);
            }
      }
      async function setearFacturaSelected(fact) {
            if (fact != null) {
                  console.log("SETEANDO FACTURA SELECTED: " + fact.id);
                  // console.log("SETEANDO FACTURA SELECTED: " + JSON.stringify(fact));
                  facturaSelectedSTORE.set(fact);
                  facturaSelected = fact;
                  tituloModal =
                        "[" +
                        fact.id +
                        "] - " +
                        fact.empresa.nombre +
                        " (" +
                        fact.nombreProductoPrincipal +
                        ") - Periodo " +
                        fact.mes +
                        "/" +
                        fact.year;

                  // Espera a que la actualización ocurra en Svelte
                  await tick();
            }
      }

      let arrHeadersTablaFacturasEstadoCuenta = [
            { nombre: "Descripcion", key: "descripcion" },
            { nombre: "Archivos", key: "archivos" },
            { nombre: "Fecha", key: "fecha" },
            { nombre: "Importe", key: "importe" },
            { nombre: "Estado", key: "estado" },
            { nombre: "Abonar", key: "abonar" },
      ];
      function acomodarDatosTablaFacturasEstadoCuenta(estadoCuenta) {
            let arrAcomodado = [];

            // 1 - COMPRUEBO ESTAOD CUENTA != NULL:
            if (estadoCuenta != null) {
                  // 2 - RECORRO LA COLECCION:
                  estadoCuenta.arrFacturas.forEach((facturaLoop) => {
                        let descripcionRTA = {
                              tipo: "img",
                              valor: facturaLoop?.nombreCalculado,
                              img: facturaLoop?.empresa?.logo?.full,
                              accion: () => openModalEstadoFacturas(facturaLoop?.id),
                        };

                        let importeRTA = facturaLoop?.strCalcularImporteFinal;

                        let archivoRTAAUX = {
                              valor: facturaLoop?.arrDocsCalc.length + " archivos",
                        };
                        if (facturaLoop != null) {
                              if (facturaLoop.arrDocsCalc != null) {
                                    if (facturaLoop.arrDocsCalc.length > 0) {
                                          let primerDocumento = facturaLoop.arrDocsCalc[0];

                                          if (primerDocumento != null) {
                                                archivoRTAAUX = {
                                                      tipo: "img-break",
                                                      valor:
                                                            facturaLoop?.arrDocsCalc?.length +
                                                            " archivos",
                                                      img: primerDocumento.previewDocumento,
                                                      clases: "flex-center-h ",
                                                      accion: () =>
                                                            openModalArchivosAttached(
                                                                  facturaLoop?.id
                                                            ),
                                                };
                                          }
                                    }
                              }
                        }
                        let archivosRTA = archivoRTAAUX;
                        let estadoRTA = {
                              tipo: "boton-outline",
                              valor: facturaLoop?.ultimoCambioEstadoFactura?.nombreEstadoActual,
                              color: facturaLoop?.ultimoCambioEstadoFactura?.colorEstadoActual,
                              icono: facturaLoop?.ultimoCambioEstadoFactura?.iconoEstadoActual,
                              accion: () => openModalEstadoFacturas(facturaLoop?.id),
                        };

                        let fechaEmisionOnlyFechaBonita = formatearOnlyFechaBonita(
                              facturaLoop?.fechaEmision
                        );

                        let fechaEmisionOnlyHoraBonita = formateaOnlyHoraBonita(
                              facturaLoop?.fechaEmision
                        );
                        let fechaRTA = {
                              valor: String(
                                    fechaEmisionOnlyHoraBonita +
                                          "<br>" +
                                          fechaEmisionOnlyFechaBonita
                              ),
                        };
                        let abonarRTA = {
                              tipo: "boton",
                              valor: "Abonar",
                              color: "#7f56da",
                              icono: "credit-card",
                              colorIcono: "white",
                              accion: () => openModalPagoFacturas(facturaLoop?.id),
                        };
                        arrAcomodado.push({
                              descripcion: descripcionRTA,
                              fecha: fechaRTA,
                              archivos: archivosRTA,
                              estado: estadoRTA,
                              importe: importeRTA,
                              abonar: abonarRTA,
                        });
                  });
            }
            return arrAcomodado;
      }
      // 5.2 - AQUI TOMO EL STORE Y LE SOBRESCRIBO LA FUNCION PROCESAR ARCHIVOS SUBIDOS PARA
      // QUE SEPA QUE TIENE QUE HACER EL STORE Y TAMBIEN LE SOBREESCRIBO LA URL:
      uploadStore.update((store) => ({
            ...store,
            uploadUrl: `${$GATEWAY.url}upload/doc/?light=true`,
            procesarArchivosSubidos: (arrFilesToUpload) => {
                  console.log(
                        "IMPLEMENTACION FILE UPLOAD - RECIBI [" +
                              arrFilesToUpload.length +
                              "] FILES TO UPLOAD:"
                  );
                  // Aquí puedes definir cómo quieres tratar los archivos subidos
                  // Por ejemplo, solo mantener ciertos campos, agregar timestamp, etc.
                  return arrFilesToUpload.map((item, index) => {
                        // const fileToUploadLoop = { ...item, fechaSubida: new Date() };
                        const fileAlreadyUploaded = { ...item };
                        console.log("FILE TO UPLOAD [" + index + "]:", fileAlreadyUploaded); // Ver cada archivo procesado

                        console.log(
                              "5.7 - DEBIRIA ATTACHAR A LA FACTURA SELECT MI ARCHIVO YA SUBIDO AL SERVIDOR:" +
                                    JSON.stringify(fileAlreadyUploaded)
                        );
                        return fileAlreadyUploaded;
                  });
            },
      }));

      function setProgreso(valor) {
            uploadStore.update((store) => ({ ...store, progreso: valor }));
      }
</script>
