<div class="cont-contenido-modal">
      <Input label="Mes" />
</div>

<script>
      import Input from "../basics/Input.svelte";
      async function enviarFactura() {
            const url = "http://localhost:8999/factura/";
            const data = {
                  mesFactura: 11,
                  yearFactura: 2024,
                  fkEmpresa: 1,
            };

            try {
                  const response = await fetch(url, {
                        method: "POST",
                        headers: {
                              accept: "*/*",
                              "Content-Type": "application/json",
                        },
                        body: JSON.stringify(data),
                  });

                  // Manejo de la respuesta
                  if (response.ok) {
                        const jsonResponse = await response.json();
                        console.log("Factura enviada:", jsonResponse);
                  } else {
                        console.error("Error al enviar la factura:", response.statusText);
                  }
            } catch (error) {
                  console.error("Error en la solicitud:", error);
            }
      }
</script>

<style>
</style>
