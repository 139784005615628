<div class="cont-contenido-modal">
      {#if facturaSelected != null}
            <HeadeVioleta texto="1 - Copiar el CBU a depositar" clases="mb-3" />
            <div class="border-violeta flex">
                  <div
                        style="width: calc(100% - 70px); color:#7f56da;"
                        class="flex-center-v violet bold"
                  >
                        {facturaSelected.cbuinstalacion}
                  </div>
                  <div style="width: 70px">
                        <BotoN
                              outline="true"
                              icono="copy"
                              fnClick={() => copiarAlPortapapeles(facturaSelected.cbuinstalacion)}
                        />
                  </div>
            </div>
            <HeadeVioleta texto="2 - Adjuntar Comprobante de Pago" clases="mt-3 mb-3" />
            <ListadoArchivosYaAdjuntos arrArchivosAdjuntos={arrComprobantesSubidos} />
            <FileUploadUltraSimple
                  bind:arrFilesBindeadosAPushear={arrComprobantesSubidos}
                  urlUP="upload/doc/factura/{facturaSelected.id}"
                  textoDragAndDrop="Arrastra tu comprobante de pago aquí.."
            />
            {arrComprobantesSubidos.length}
      {/if}
      {#if DEBUG}
            DEBUG
            <br />
            facturaSelected:{JSON.stringify(facturaSelected)}
            <br />
            ARR COMPROBANTES SUBIDOS:
            {JSON.stringify(arrComprobantesSubidos)}
      {/if}
</div>

<script>
      // import { facturaSelectedSTORE } from "../../stores/factura-selected-store";
      import BotoN from "../basics/BotoN.svelte";
      import HeadeVioleta from "../basics/HeadeVioleta.svelte";
      import FileUploadUltraSimple from "../basics/FileUploadUltraSimple.svelte";
      import { addNotification } from "../../stores/notificationStore";
      import { onMount } from "svelte";
      import ListadoArchivosYaAdjuntos from "../basics/ListadoArchivosYaAdjuntos.svelte";

      export let DEBUG = false;
      export let propsSlot = {};
      export let selected = null;
      let facturaSelected = null;
      let arrComprobantesSubidos = [];
      $: console.log("COMPROBANTES SUBIDOS: " + arrComprobantesSubidos.length);

      onMount(() => {
            facturaSelected = selected;
      });

      $: DEBUG = propsSlot.DEBUG ?? DEBUG;

      function copiarAlPortapapeles(texto) {
            navigator.clipboard
                  .writeText(texto)
                  .then(() => {
                        console.log("Texto copiado:", texto);
                        addNotification("CBU Copiado: " + texto, "check", "#2c3e50");
                        // alert(`Texto copiado: ${texto}`);
                  })
                  .catch((err) => {
                        console.error("Error al copiar el texto:", err);
                        addNotification("No pude copiar el CBU", "cross", "#f44336");
                        // alert("Error al copiar el texto");
                  });
      }
      // let facturaSelected;
      // $: facturaSelected = $facturaSelectedSTORE;
</script>

<style>
      .header-cambio-estados {
            margin-top: 15px;
            text-align: center;
            background-color: #1abc9c;
            color: white;
            padding: 15px;
            /* font-weight: bold; */
            font-size: 24px;
            border-radius: 50px 50px 0px 0px;
      }
      .border-violeta {
            padding: 12px;
            border-radius: 10px 10px 10px 10px;
            /* border-bottom: solid 4px #7f56da; */
            /* border-left: solid 1px #7f56da; */
            /* border-top: solid 1px #7f56da; */
            /* border-right: solid 1px #7f56da; */
      }
</style>
