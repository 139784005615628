{#if RUTA_ACTUAL != "/login"}
      <Banner {RUTAS_HUMAN} {RUTA_ACTUAL} color="#8e44ad" fnExit={exit} />
{/if}

<main>
      <Router routes={RUTAS_COMPONENTES} />
</main>

{#if RUTA_ACTUAL != "/login"}
      <!-- OPERADOR_LOGEADO:{JSON.stringify(operadorLogeado)} -->
      <Footer {operadorLogeado} />
{/if}

<script>
      import Router from "svelte-spa-router";
      import { location as LOCATION_STORE } from "svelte-spa-router";
      import Banner from "./componentes/formato/Banner.svelte"; // Componente del banner
      import { RUTAS_COMPONENTES, RUTAS_HUMAN } from "./utils/ROUTING.js"; // Importa las rutas definidas
      import { evaluateWindowSize, debug } from "./utils/resize-listener.js";
      import { onMount, onDestroy } from "svelte";
      import Footer from "./componentes/formato/Footer.svelte";
      import "bootstrap/dist/css/bootstrap.min.css";
      import {
            OPERADOR_LOGEADO_STORE,
            comprobarOperadorLogeado,
            exit,
      } from "./stores/operador-logeado-store.js";
      let operadorLogeado;
      $: operadorLogeado;

      // 0 - ME SUSCRIBO A TOODS LOS STORES:
      // 1 - STORE PARA EL ROUTING LA RUTA ACTUAL:
      let RUTA_ACTUAL = "/";
      const unsubscribeLocationStore = LOCATION_STORE.subscribe((value) => {
            RUTA_ACTUAL = value;
      });

      const unsubscribeOperadorStore = OPERADOR_LOGEADO_STORE.subscribe((store) => {
            operadorLogeado = store.operadorLogeado;
      });

      // 2 - LISTENER PARA RESIZE DE LA WINDOW:
      onMount(() => {
            evaluateWindowSize();
            window.addEventListener("resize", evaluateWindowSize);

            comprobarOperadorLogeado();
      });
      // 99 - ME DESUSCRIBO DE TODOS LOS STORES:
      onDestroy(() => {
            window.removeEventListener("resize", evaluateWindowSize);
            unsubscribeOperadorStore();
            unsubscribeLocationStore();
      });
</script>
