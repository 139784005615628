// src/store.js
import { writable } from "svelte/store";
import { isProd } from "../utils/isProd.js";

console.log("IS PROD: " + isProd);

let url = "http://localhost:8999/";

if (isProd) {
      url = "https://api.payments.com.ar/";
}
export const GATEWAY = writable({
      url: url,
});
