<script>
      import { onMount } from "svelte";

      export let w = "100%";
      export let clases = "";
      export let bold = false;
      export let texto = "Texto";
      export let fontSize = "14px";
      export let border = false;
      export let pl = "8px";
      export let pr = "8px";
      export let pt = "4px";
      export let pb = "4px";
      export let bgColor = "transparent";

      // Nueva propiedad para controlar el inicio automático
      export let autoStart = false;

      let h =
            extractNumericValue(fontSize) +
            extractNumericValue(pt) +
            extractNumericValue(pb) +
            10 +
            "px";
      let realH = h;
      export const scrollSpeed = 20;
      export let delayInicio = 2000; // Tiempo de espera inicial en milisegundos
      // export let delayFin = 1000; // Tiempo de espera al final en milisegundos

      let scrollPosition = 0;
      let maxScrollPosition = 0;
      let scrollInterval;
      let goingForward = true; // Control de dirección
      let largoTexto = 0;
      let largoCaja = 0;
      let limiteMaxScroll = 0;
      let anchoMaxSegunCaracteres = 0;
      const step = 1;
      export let debug = false;

      function extractNumericValue(value) {
            return parseFloat(value); // Convierte la cadena en un número
      }

      onMount(() => {
            if (debug) {
                  realH = extractNumericValue(h) * 4;
                  console.log("REAL H: " + realH);
            }
            const textElement = document.querySelector(".scroll-text");
            const containerElement = document.querySelector(".scroll-container");

            // Función para calcular el ancho del texto y actualizar maxScrollPosition
            const calculateMaxScrollPosition = () => {
                  const textWidth = textElement.getBoundingClientRect().width;
                  maxScrollPosition = textWidth - containerElement.offsetWidth;
            };
            // Calcular maxScrollPosition al inicio
            calculateMaxScrollPosition();
            largoTexto = texto.length;
            anchoMaxSegunCaracteres = getEstimatedTextWidth(largoTexto, fontSize);
            largoCaja = containerElement.getBoundingClientRect().width;

            if (debug) {
                  console.log(`El largoTexto es : ${largoTexto}px`);
                  console.log(`El ancho anchoMaxSegunCaracteres: ${anchoMaxSegunCaracteres}px`);
            }

            let padding_l = extractNumericValue(pl);
            let padding_r = extractNumericValue(pl);
            let total_padding = padding_l + padding_r;
            limiteMaxScroll = anchoMaxSegunCaracteres - largoCaja + total_padding;

            // Iniciar el desplazamiento si autoStart es true
            if (autoStart) {
                  setTimeout(startScrolling, delayInicio);
            }

            // Limpiar el intervalo al desmontar el componente
            return () => clearInterval(scrollInterval);
      });

      function getEstimatedTextWidth(largoTexto, fontSize) {
            let largoInterno = extractNumericValue(largoTexto);
            let fontSizeInterno = extractNumericValue(fontSize);
            if (debug) {
                  console.log(`LARGO: ${largoInterno}px`);
                  console.log(`FONT-SIZE: ${fontSizeInterno}px`);
            }
            let multiplicador = 0.5;
            if (bold) {
                  multiplicador = 0.53;
            }
            return largoInterno * (fontSizeInterno * multiplicador); // Total en píxeles
      }

      function startScrolling() {
            scrollInterval = setInterval(() => {
                  // Ajustar la posición de desplazamiento según la dirección
                  scrollPosition += goingForward ? step : -step;

                  // Control para revertir la dirección al alcanzar los límites
                  if (scrollPosition >= limiteMaxScroll) {
                        goingForward = false;
                  } else if (scrollPosition <= 0) {
                        goingForward = true;
                        clearInterval(scrollInterval); // Detener el intervalo cuando llegue a 0
                  }
            }, scrollSpeed);
      }

      // Manejo del evento hover para iniciar el desplazamiento
      function handleMouseEnter() {
            if (!autoStart) {
                  startScrolling();
            }
      }

      // Manejo del evento mouseleave para detener el desplazamiento y regresar a la posición 0
      function handleMouseLeave() {
            clearInterval(scrollInterval); // Detener el desplazamiento
            scrollPosition = 0; // Regresar a la posición inicial
      }
</script>

<div
      class="scroll-container"
      style="width:{w}; height:{realH}; background-color: {bgColor}"
      class:border-class={border}
      on:mouseenter={handleMouseEnter}
      on:mouseleave={handleMouseLeave}
>
      <div
            class="scroll-text {debug ? 'bsr' : ''} {bold ? 'bold' : ''} {clases}"
            style="transform: translateX(-{scrollPosition}px); font-size:{fontSize};
      padding-right: {pr};
      padding-left: {pl};
      padding-top: {pt};
      padding-bottom: {pb};"
      >
            {texto}
      </div>
      <div class="col-12">
            {#if debug}
                  Largo texto : {largoTexto} caracteres <br />
                  Largo caja : {largoCaja} px<br />
                  Limite : {limiteMaxScroll} px<br />
                  {scrollPosition} de {anchoMaxSegunCaracteres} px largoTexto: {largoTexto}
            {/if}
      </div>
</div>

<style>
      .scroll-container {
            overflow: hidden;
            position: relative;
            /* background-color: #fff; */
      }

      .scroll-text {
            white-space: nowrap;
            display: inline-block;
            color: #333;
            cursor: pointer;
      }
      .border-class {
            border: 1px solid #ddd;
      }
</style>
