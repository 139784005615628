import { writable, get } from "svelte/store";
import { GATEWAY } from "../stores/gateway-store";

// Definimos el store uploadStore con la URL y otros valores
export const uploadStore = writable({
      arrArchivosSubidos: [], // Mantener el estado inicial de los archivos subidos
      cargandoSubidaArchivos: false,
      uploadURL: "http://localhost:1234/upload/doc/?light=true",
      progreso: 20,

      // Método para recibir una nueva lista de archivos
      setArchivosSubidos(arrArchivos) {
            uploadStore.update((store) => ({
                  ...store,
                  arrArchivosSubidos: arrArchivos,
            }));
      },

      async subirArchivos(arrArchivos) {
            // Obtener la URL del store
            let URL_UPLOAD_INTERNA = get(uploadStore).uploadURL;
            let FN_PROCESAR_ARCHIVOS_SUBIDOS_INTERNA = get(uploadStore).procesarArchivosSubidos;
            console.log(
                  "5.3 - REALMENTE SUBIENDO LOS ARCHIVOS AL SERVIDOR [NETWORK] @ " +
                        URL_UPLOAD_INTERNA
            );

            // Activar estado de carga
            uploadStore.update((store) => ({ ...store, cargandoSubidaArchivos: true }));

            try {
                  const formData = new FormData();
                  arrArchivos.forEach((archivo) => {
                        formData.append("doc", archivo);
                  });

                  const xhr = new XMLHttpRequest();
                  xhr.open("POST", URL_UPLOAD_INTERNA, true); // Usa la URL interna del store

                  xhr.upload.onprogress = (event) => {
                        if (event.lengthComputable) {
                              const porcentaje = Math.round((event.loaded / event.total) * 100);
                              // Actualizar el progreso en el store
                              uploadStore.update((store) => ({ ...store, progreso: porcentaje }));
                              console.log("PROGRESO UP: " + porcentaje);
                        }
                  };

                  xhr.onload = () => {
                        if (xhr.status === 200) {
                              const resultado_back = JSON.parse(xhr.responseText);
                              console.log(
                                    "5.4 - resultado_back: " +
                                          JSON.stringify(resultado_back) +
                                          " -> type : " +
                                          typeof resultado_back
                              );

                              // Procesar el resultado y actualizar el store
                              let archivosProcesados;
                              if (Array.isArray(resultado_back)) {
                                    archivosProcesados =
                                          FN_PROCESAR_ARCHIVOS_SUBIDOS_INTERNA(resultado_back);
                              } else {
                                    archivosProcesados = FN_PROCESAR_ARCHIVOS_SUBIDOS_INTERNA([
                                          resultado_back,
                                    ]);
                              }

                              let storeActual = get(uploadStore);
                              let arrNvosArchivosSubidosActualizado =
                                    storeActual.arrArchivosSubidos;
                              for (const archivoNuevoLoop of archivosProcesados) {
                                    arrNvosArchivosSubidosActualizado.push(archivoNuevoLoop);
                                    console.log(
                                          "NUEVO ARCHIVO POR APPENDER:" +
                                                JSON.stringify(archivoNuevoLoop)
                                    );
                              }

                              uploadStore.update((store) => ({
                                    ...store,
                                    arrArchivosSubidos: arrNvosArchivosSubidosActualizado,
                                    progreso: 20,
                                    cargandoSubidaArchivos: false,
                              }));

                              uploadStore.update((store) => ({
                                    ...store,
                                    progreso: 100,
                              }));
                        } else {
                              console.error("Error al subir los archivos");
                              uploadStore.update((store) => ({
                                    ...store,
                                    progreso: 20,
                                    cargandoSubidaArchivos: false,
                              }));
                              throw new Error("Error al subir los archivos");
                        }
                  };

                  xhr.onerror = () => {
                        console.error("Error en la solicitud");
                        uploadStore.update((store) => ({
                              ...store,
                              progreso: 20,
                              cargandoSubidaArchivos: false,
                        }));
                        throw new Error("Error en la solicitud");
                  };

                  xhr.send(formData);
            } catch (error) {
                  console.error("Error en subirArchivos:", error);
                  uploadStore.update((store) => ({ ...store, cargandoSubidaArchivos: false }));
                  throw error;
            }
      },

      cambiarURL(url) {
            let gatewayUrl = get(GATEWAY).url;
            let newURL = gatewayUrl + "" + url;
            console.log("CAMBIANDO URL UPLOAD STORE: " + newURL);

            // Actualizar la URL dentro del store
            uploadStore.update((store) => ({
                  ...store,
                  uploadURL: newURL,
            }));
      },
});
