<script>
      import Pagina from "../componentes/formato/PaginaBase.svelte";
      // import { location as LOCATION_STORE } from "svelte-spa-router";

      import CuadroLogin from "../componentes/basics/Cuadro-Login.svelte";
      import Img from "../componentes/basics/Img.svelte";

      // let RUTA_ACTUAL = "/";
      // LOCATION_STORE.subscribe((value) => {
      //       RUTA_ACTUAL = value;
      // });

      let urlImg = "imgs/bg-login.png";
      let newUser = {
            nombre: "",
            apellido: "",
            email: "",
            clave: "",
      };
</script>

<Pagina altoBanner="0px">
      <div class="cont-full-login col-12 flex sin-padding">
            <div class="panel-login-izq col-6">
                  <Img h="100vh" w="100%" src={urlImg} br="0" />
            </div>
            <div class="panel-login-der col-6">
                  <CuadroLogin />
            </div>
      </div>
      <!-- <h3>RUTA ACTUAL: {RUTA_ACTUAL}</h3> -->
</Pagina>
