<div class="cont-file-upload-simple" on:drop={handleDrop} on:dragover={handleDragOver}>
      <input type="file" id="file-input" on:change={handleFileUpload} hidden />
      <!-- Zona de arrastre para archivos -->

      {#if !FINISH_LOADING}
            <!-- {#if cargandoSubidaArchivos} -->
            <div class="barra-cargando mb-3 mt-3">
                  <BarraProgreso bind:porcentaje={PROGRESO} />
            </div>
            {#each arrArchivosTemp as archivoTempLoop}
                  <ItemArchivoTemp archivoTEMP={archivoTempLoop} />
                  <!-- {JSON.stringify(archivoTempLoop)} -->
            {/each}
      {:else}
            <div
                  class="header-si-no-tengo-archivos pointer"
                  on:click={callInputUpload}
                  on:keypress={callInputUpload}
            >
                  <IconoNico name="cloud" color="grey" w="72px" h="72px" />
                  <p>{textoDragAndDrop}</p>
            </div>
      {/if}

      <!-- FINISH_LOADING: {FINISH_LOADING} -->
</div>
<!-- Lista de archivos subidos -->
<!-- FILES MULTIPART: {JSON.stringify(arrArchivosTemp)} -->

<div class="cont-botonera-upload-simple pt-2 flex-end">
      <BotonNico texto="Subir Archivos" icono="cloud" fnClick={callInputUpload} />
</div>

<script>
      import BotonNico from "./BotonNico.svelte";
      import IconoNico from "./IconoNico.svelte";
      import BarraProgreso from "./BarraProgreso.svelte";
      import { onMount } from "svelte";
      import { GATEWAY } from "../../stores/gateway-store";
      import ItemArchivoTemp from "./ItemArchivoTemp.svelte";

      export let arrFilesBindeadosAPushear = [];
      export let urlUP = "http://localhost:1234/upload/doc/?light=true";
      export let textoDragAndDrop = "Arrastra ó suelta tus archivos aquí..";

      let isDragging = false;
      let PROGRESO = 0;
      let cargandoSubidaArchivos = false;
      let FINISH_LOADING = true;
      let arrArchivosTemp = []; // Variable reactiva para manejar los archivos

      function handleFileUpload(event) {
            const arrNewFilesToUpload = Array.from(event.target.files);
            actualizarArchivos(arrNewFilesToUpload);
            iniciarSubida(arrNewFilesToUpload);
      }

      function handleDrop(event) {
            event.preventDefault();
            const arrNewFilesToUpload = Array.from(event.dataTransfer.files);
            actualizarArchivos(arrNewFilesToUpload);
            iniciarSubida(arrNewFilesToUpload);
      }

      function handleDragOver(event) {
            event.preventDefault();
            isDragging = true;
      }

      function callInputUpload() {
            document.getElementById("file-input").click();
      }

      function actualizarArchivos(nuevosArchivos) {
            arrArchivosTemp = [...arrArchivosTemp, ...nuevosArchivos]; // Actualiza la lista de archivos
      }

      onMount(() => {
            urlUP = $GATEWAY.url + urlUP;
            console.log("URL SETUP - ON MOUNT:", urlUP);
      });

      async function iniciarSubida(arrFiles) {
            PROGRESO = 0;
            cargandoSubidaArchivos = true;

            try {
                  const formData = new FormData();
                  arrFiles.forEach((file) => formData.append("doc", file));

                  const xhr = new XMLHttpRequest();
                  xhr.open("POST", "http://localhost:8999/upload/doc/factura/1", true);

                  FINISH_LOADING = false;

                  xhr.upload.onprogress = (event) => {
                        if (event.lengthComputable) {
                              PROGRESO = Math.round((event.loaded / event.total) * 100);
                        }
                  };

                  xhr.onload = () => {
                        if (xhr.status === 200) {
                              const resultado_back = JSON.parse(xhr.responseText);
                              console.log("Archivos subidos con éxito:", resultado_back);

                              const archivosProcesados = Array.isArray(resultado_back)
                                    ? resultado_back
                                    : [resultado_back];

                              arrFilesBindeadosAPushear = [
                                    ...arrFilesBindeadosAPushear,
                                    ...archivosProcesados,
                              ];

                              arrArchivosTemp = [];

                              PROGRESO = 100;
                              setTimeout(() => {
                                    PROGRESO = 0;
                                    FINISH_LOADING = true;
                              }, 1000);
                        } else {
                              console.error("Error al subir los archivos");
                        }
                        cargandoSubidaArchivos = false;
                  };

                  xhr.onerror = () => {
                        console.error("Error en la solicitud");
                        cargandoSubidaArchivos = false;
                        FINISH_LOADING = true;
                  };

                  xhr.send(formData);
            } catch (error) {
                  console.error("Error en subir archivos:", error);
                  cargandoSubidaArchivos = false;
            }
      }
</script>

<style>
      .cont-file-upload-simple {
            text-align: center;
            border: 2px dashed #6b3fbc;
            border-radius: 8px;
            padding: 20px;
            color: #333;
      }

      .barra-cargando {
            margin-top: 20px;
            text-align: center;
      }

      .header-si-no-tengo-archivos {
            cursor: pointer;
      }

      .cont-botonera-upload-simple {
            padding-top: 20px;
            display: flex;
            justify-content: flex-end;
      }
</style>
