<div class="cont-contenido-modal">
      <!-- {facturaSelected} -->
      {#if facturaSelected != null}
            <!-- 1 - PARTE SUPERIOR - HEADER DATOS PRINCIPALES: -->
            <div
                  class="col-12 bsv flex"
                  style="border-radius: 10px 10px 10px 10px; border-bottom: solid 4px #7f56da"
            >
                  <div class="col-4">
                        <div
                              class="fila-1-importe violet poppins-semibold flex-start p-2"
                              style="padding-left:25px !important"
                        >
                              Cliente
                        </div>
                  </div>
                  <div class="col-4">
                        <div class="fila-1-importe violet poppins-semibold flex-center p-2">
                              Estado Factura
                        </div>
                  </div>
                  <div class="col-4">
                        <div
                              class="fila-1-importe violet poppins-semibold flex-end p-2"
                              style="padding-right:25px !important"
                        >
                              Importe Final
                        </div>
                  </div>
            </div>

            <!--- 2 - DATOS PRINCIPALES-->
            <div
                  class="cont-datos-principales-fact col-12 bsg flex mt-3"
                  style="border-radius: 10px 10px 10px 10px;"
            >
                  <!-- 2.1 DATOS PRINCIPALES COL 1: -->
                  <div class="datos-principales-c-1 col-3 p-2">
                        <div class="centrador-img-empresa flex-center">
                              <Img
                                    src={facturaSelected?.empresa?.logo?.full}
                                    br="50%"
                                    w="50px"
                                    h="50px"
                              />
                        </div>
                        <div class="flex-center poppins-semi-bold pt-2 grey">
                              {facturaSelected?.empresa?.nombre}
                        </div>
                  </div>

                  <!-- 2.2 DATOS PRINCIPALES COL 2: (DATOS ULTIMO CAMBIO ESTADO) -->
                  <div class="datos-principales-c-2 col-6 flex-center-h flex-center-v">
                        <div class="col-12">
                              <div
                                    class="datos-principales-estado-factura grey poppins semi-bold flex-center"
                                    style="color: {facturaSelected?.ultimoCambioEstadoFactura
                                          ?.colorEstadoActual} !important"
                              >
                                    {facturaSelected?.ultimoCambioEstadoFactura?.nombreEstadoActual}
                              </div>
                              <div
                                    class="datos-principales-hora-emision-factura grey poppins semi-bold flex-center"
                                    style="color: {facturaSelected?.ultimoCambioEstadoFactura
                                          ?.colorEstadoActual} !important"
                              >
                                    {facturaSelected?.ultimoCambioEstadoFactura?.onlyHoraBonita}
                              </div>
                              <div
                                    class="datos-principales-fecha-emision-factura grey poppins semi-bold flex-center"
                                    style="color: {facturaSelected?.ultimoCambioEstadoFactura
                                          ?.colorEstadoActual} !important"
                              >
                                    {facturaSelected?.ultimoCambioEstadoFactura?.onlyFechaBonita}
                              </div>
                        </div>
                  </div>

                  <!-- 2.3 DATOS PRINCIPALES COL 3 (IMPORTE): -->
                  <div class="datos-principales-c-3 col-3 flex-center-h flex-center-v grey bold">
                        {facturaSelected?.strCalcularImporteFinal}
                  </div>
            </div>

            <!-- 3- CAMBIOS ESTADO FACTURA:-->
            <div class="cont-servicios">
                  <HeadeVioleta
                        clases="mt-3 mb-2"
                        texto="{facturaSelected?.arrDetsFactura?.length} Producto{facturaSelected
                              ?.arrDetsFactura?.length > 1
                              ? 's'
                              : ''} en la Factura"
                  />

                  <TablaSimple
                        arrHeaders={arrHeadersTablaDetFactura}
                        arrDatos={acomodarDatosTablaDetFactura(facturaSelected)}
                  />
            </div>

            <!-- 3- CAMBIOS ESTADO FACTURA:-->
            <div class="cont-cambios-estado">
                  <HeadeVioleta
                        clases="mt-3 mb-2"
                        texto="{facturaSelected?.arrCambiosEstado?.length} Cambio{facturaSelected
                              ?.arrCambiosEstado?.length > 1
                              ? 's'
                              : ''} de Estado de la Factura"
                  />

                  <TablaSimple
                        arrHeaders={arrHeadersTablaCambioEstado}
                        arrDatos={acomodarDatosCambioEstadoFactura(facturaSelected)}
                  />
                  <!-- {facturaSelected?.arrCambiosEstado.length} -->
            </div>

            <div class="cont-up-files">
                  <HeadeVioleta
                        clases="mt-3 mb-2"
                        texto="{facturaSelected?.arrDocsCalc
                              .length} Documentos asociados a la factura"
                  />
                  <ListadoArchivosYaAdjuntos
                        bind:arrArchivosAdjuntos={facturaSelected.arrDocsCalc}
                  />
                  <HeadeVioleta clases="mt-3 mb-2" texto="Adjuntar nuevos Documentos.." />
                  <FileUploadUltraSimple
                        urlUP="upload/doc/factura/{facturaSelected.id}"
                        bind:arrFilesBindeadosAPushear={facturaSelected.arrDocsCalc}
                  />
                  <!-- bind:FINISH_LOADING -->
                  <!-- FINISH_LOADING:{FINISH_LOADING} -->
            </div>

            <!-- FACTURA SELECTED:{JSON.stringify(facturaSelected)} -->
      {/if}
</div>

<script>
      import { onMount } from "svelte";
      import { facturaSelectedSTORE } from "../../stores/factura-selected-store";
      import ImgSimple from "../basics/ImgSimple.svelte";
      import TablaSimple from "../basics/TablaSimple.svelte";
      import Img from "../basics/Img.svelte";
      import HeadeVioleta from "../basics/HeadeVioleta.svelte";
      import FileUpload from "../basics/FileUpload.svelte";
      // import FileUploadSimple from "../basics/FileUploadSimple.svelte";
      import FileUploadUltraSimple from "../basics/FileUploadUltraSimple.svelte";
      import ListadoArchivosYaAdjuntos from "../basics/ListadoArchivosYaAdjuntos.svelte";

      let facturaSelected;
      // let FINISH_LOADING = false;
      $: facturaSelected = $facturaSelectedSTORE;

      let arrHeadersTablaDetFactura = [
            { nombre: "Productos", key: "producto" },
            { nombre: "Precio", key: "precio" },
      ];
      function acomodarDatosTablaDetFactura(facturaRecibida) {
            let arrAcomodado = [];

            if (facturaRecibida != null) {
                  let arrDets = facturaRecibida.arrDetsFactura;
                  console.log("ACOMODANDO DATOS TABLA DET FACTURA: " + arrDets.length);

                  arrDets.forEach((detLoop) => {
                        // console.log("DET FACTURA RECIBIDA: " + JSON.stringify(detLoop));

                        let productoReal = detLoop?.producto;
                        let productoConvertido = {
                              tipo: "imagen",
                              valor:
                                    productoReal?.nombre +
                                    " - (" +
                                    facturaRecibida.mes +
                                    "/" +
                                    facturaRecibida.year +
                                    ")",
                              img: productoReal?.foto?.full,
                        };
                        let precioConvertido = {
                              // tipo: "boton",
                              valor: productoReal?.precio,
                              // accion: () => alert("Detalles del producto"),
                        };

                        arrAcomodado.push({
                              producto: productoConvertido,
                              precio: precioConvertido,
                        });
                  });
            }

            return arrAcomodado;
      }

      let arrHeadersTablaCambioEstado = [
            { nombre: "Descripcion", key: "descripcion" },
            { nombre: "Operador", key: "operador" },
            { nombre: "Fecha", key: "fecha" },
            { nombre: "Estado", key: "estado" },
      ];
      function acomodarDatosCambioEstadoFactura(facturaRecibida) {
            let arrAcomodado = [];

            if (facturaRecibida != null) {
                  let arrCambiosEstado = facturaRecibida.arrCambiosEstado;
                  console.log("ACOMODANDO DATOS TABLA CAMBIO ESTADO: " + arrCambiosEstado.length);

                  arrCambiosEstado.forEach((cambioEstadoLoop) => {
                        // console.log("CAMBIO ESTADO LOOP: " + JSON.stringify(cambioEstadoLoop));

                        let responsableReal = cambioEstadoLoop?.responsable;
                        let operadorConvertido = {
                              // tipo: "imagen",
                              valor: responsableReal.apellido + ", " + responsableReal.nombre,
                              // img: responsableReal?.fotoPerfil?.full,
                        };

                        let fechaRTA =
                              cambioEstadoLoop?.onlyHoraBonita +
                              "<br>" +
                              cambioEstadoLoop?.onlyFechaBonita;

                        let estadoRTA = {
                              tipo: "boton",
                              valor: cambioEstadoLoop?.nombreEstadoActual,
                              color: cambioEstadoLoop?.colorEstadoActual,
                              icono: cambioEstadoLoop?.iconoEstadoActual,
                              accion: () => alert("Detalles del producto"),
                        };

                        let descripcionRTA =
                              "<div class='grey-span'>" +
                              facturaRecibida?.nombreProductoPrincipal +
                              "</div>" +
                              "<div class='black-span'>" +
                              "Periodo " +
                              facturaRecibida?.mes +
                              "/" +
                              facturaRecibida?.year +
                              "</div>";

                        arrAcomodado.push({
                              descripcion: descripcionRTA,
                              operador: operadorConvertido,
                              // archivos: archivosRTA,
                              fecha: fechaRTA,
                              estado: estadoRTA,
                        });
                  });
            }

            return arrAcomodado;
      }
</script>

<style>
      .header-cambio-estados {
            margin-top: 15px;
            text-align: center;
            background-color: #1abc9c;
            color: white;
            padding: 15px;
            /* font-weight: bold; */
            font-size: 24px;
            border-radius: 50px 50px 0px 0px;
      }
</style>
