<div class="cont-listado-archivos-ya-adjuntos">
      {#each arrArchivosAdjuntos as archivoAdjuntoLoop}
            <ItemArchivoAdjunto
                  archivoBack={archivoAdjuntoLoop}
                  bind:arrFilesToRemove={arrArchivosAdjuntos}
            />
      {/each}

      <!-- DEBUG: -->
      <!-- {#each arrArchivosAdjuntos as archivoAdjuntoLoop}
            <div class="bsr">
                  {JSON.stringify(archivoAdjuntoLoop)}
            </div>
      {/each} -->
      <!-- {arrArchivosAdjuntos.length} -->
</div>

<script>
      import ItemArchivoAdjunto from "./ItemArchivoAdjunto.svelte";

      export let arrArchivosAdjuntos = [];
</script>

<style>
      .cont-listado-archivos-ya-adjuntos {
            border: solid 1px #ddd;
            border-radius: 12px;
            padding: 12px;
      }
</style>
