<div class="cont-input">
      <!-- {labelFloated} -->
      <label class="label-input {labelFloated ? 'floated' : ''}" for={inputId}>
            {label}
      </label>
      {#if type === "text"}
            <input
                  type="text"
                  class="input"
                  class:error-input-material={!realmenteValido}
                  id={inputId}
                  bind:value
                  on:keypress={listenerEnterKey}
                  on:focus={() => (isFocused = true)}
                  on:blur={() => {
                        isFocused = false;
                        validarCampo();
                  }}
            />
      {:else if type === "email"}
            <input
                  type="email"
                  class="input"
                  class:error-input-material={!realmenteValido}
                  id={inputId}
                  on:keypress={listenerEnterKey}
                  bind:value
                  on:focus={() => (isFocused = true)}
                  on:blur={() => {
                        isFocused = false;
                        validarCampo();
                  }}
            />
      {:else if type === "password"}
            <input
                  type="password"
                  class="input"
                  class:error-input-material={!realmenteValido}
                  on:keypress={listenerEnterKey}
                  bind:value
                  id={inputId}
                  on:focus={() => (isFocused = true)}
                  on:blur={() => {
                        isFocused = false;
                        validarCampo();
                  }}
            />
      {/if}

      <div class="cont-error">
            <span class="supporting-text">
                  {#if !realmenteValido}
                        {#if type === "email"}
                              {errorMSG} El {label} debe ser un email valido..
                        {:else}
                              {errorMSG} El {label}, debe tener un largo minimo de {largoMinValidacion}
                              caracteres..
                        {/if}
                  {/if}

                  {#if debug}
                        {errorMSG} | {value.length} | {largoMinValidacion}
                        <br />

                        {#if valido}
                              <i class="fa-solid fa-check"></i>
                        {:else}
                              <i class="fa-solid fa-xmark"></i>
                        {/if}

                        valido:{valido}
                        <br />
                        {#if dirty}
                              <i class="fa-solid fa-check"></i>
                        {:else}
                              <i class="fa-solid fa-xmark"></i>
                        {/if}
                        dirty:{dirty}
                        <br />

                        {#if realmenteValido}
                              <i class="fa-solid fa-check"></i>
                        {:else}
                              <i class="fa-solid fa-xmark"></i>
                        {/if}
                        realmenteValido:{realmenteValido}
                  {/if}
            </span>
      </div>
</div>

<script>
      import { validarEmailRegex, validarTexto } from "../../utils/utils.js";
      import { onMount } from "svelte";
      export let label = "Nombre";
      export let errorMSG = "Error:";
      export let value = "";
      export let type = "text";
      export let largoMinValidacion = 2;
      export let debug = false;
      let isFocused = false;
      export let realmenteValido;
      export let fnEnter;

      // Determinar si el label debe estar en su estado "flotante"
      $: realmenteValido = valido || !dirty || isFocused;
      $: labelFloated = isFocused || value.length > 0;
      $: value = String(value);
      const inputId = Math.random().toString(36).substring(2, 15);

      // onMount(() => {
      //       if (value.length > 0) {
      //             labelFloated = true;
      //             console.log("VALUE TIENE ALGO");
      //       } else {
      //             console.log("VALUE == 0");
      //       }
      // });

      let valido = false;
      let dirty = false;
      const validarCampo = function () {
            console.log("VALIDANDO : " + label + " - LARGO MINIMO: " + largoMinValidacion);

            dirty = true;

            // 1 - VALIDO CAMPO:
            if (type === "email") {
                  valido = validarEmailRegex(value);
            } else {
                  if (validarTexto(value, largoMinValidacion)) {
                        valido = true;
                  } else {
                        valido = false;
                  }
            }
      };
      function listenerEnterKey(event) {
            let teclaPresionada = event.key;

            if (fnEnter != null) {
                  if (teclaPresionada == "Enter") {
                        console.log("PRESIONASTE ENTER");
                        fnEnter();
                  }
            }
            // console.log("Tecla presionada:", event.key);
      }
</script>

<style>
      .cont-input {
            display: flex;
            flex-direction: column;
            position: relative;
            margin: 20px 0;
            font-family: "Roboto", sans-serif;
            padding-left: 25px;
            padding-right: 25px;
      }

      .label-input {
            position: absolute;
            left: 40px;
            top: 16px;
            /* background-color: #f8f5ff; */
            padding: 0 4px;
            font-size: 16px;
            color: #5f5f5f;
            transition:
                  top 0.2s ease,
                  font-size 0.2s ease;
            cursor: text;
            transition: 0.2s;
      }

      .label-input.floated {
            top: -10px;
            left: 50px;
            font-size: 14px;
            color: #7f56da;
            background-color: white;
            border-radius: 6px;
            transition: 0.2s;
      }

      .input {
            /* padding: 16px 12px; */
            padding: 16px;
            border: 1px solid #c3c3c3;
            border-radius: 4px;
            font-size: 16px;
            outline: none;
            transition: border-color 0.2s;
            border-radius: 50px;
      }

      .input:focus {
            border-color: #7f56da;
      }

      .cont-error {
            margin-top: 8px;
            /* background-color: black; */
            padding-left: 25px;
      }
      .supporting-text {
            margin-top: 4px;
            font-size: 12px;
            color: red;
      }
      .error-input-material {
            border: solid 2px red !important;
      }

      /* .cont-p-error-input-material {
            padding-left: 75px;
            padding-top: 6px;
            color: red;
      } */
</style>
