<div class="cont-pagina-base {clase}" style="min-height: calc(100vh - {altoBanner});">
      <slot></slot>
</div>
<Toastada />

<script>
      import Toastada from "../basics/Toastada.svelte";

      export let altoBanner = "80px";
      export let clase = "";
</script>

<style>
      .cont-pagina-base {
            max-width: 100vw;
            overflow-x: hidden;
      }
</style>
