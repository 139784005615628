// src/lib/utils.js
export const isNumeric = (value) => {
      return !isNaN(value) && !isNaN(parseFloat(value));
};
/**
 * Valida si un texto tiene al menos un largo mínimo.
 *
 * @param {string} texto - El texto a validar.
 * @param {number} [largoMinimo=3] - El largo mínimo requerido (por defecto es 3).
 * @returns {boolean} - Retorna `true` si el texto cumple con el largo mínimo, de lo contrario `false`.
 */
export function validarTexto(texto, largoMinimo = 3) {
      let ok = false;

      if (texto != null) {
            if (texto.length >= largoMinimo) {
                  ok = true;
            }
      }

      return ok;
}
export function validarEmailRegex(emailRecibido) {
      let emailOK = false;
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;

      // Verificar si el email es válido
      if (emailRegex.test(emailRecibido)) {
            emailOK = true;
            // Llama a la función que reemplaza $scope.PREgetPacienteByEmail
            // PREgetPacienteByEmail(emailRecibido);
      } else {
            emailOK = false;
      }

      return emailOK;
}
export function formatearFullFechaBonita(fechaJava) {
      let fechaBonita = "xx/xx/xx";
      if (fechaJava != null) {
            if (fechaBonita.length > 5) {
                  let dia = agregarCero(fechaJava[2]);
                  let mes = agregarCero(fechaJava[1]);
                  let year = agregarCero(fechaJava[0]);
                  let hora = agregarCero(fechaJava[3]);
                  let minutos = agregarCero(fechaJava[4]);
                  let segundos = agregarCero(fechaJava[5]);

                  year = String(year).substring(2, 4);

                  fechaBonita = dia + "/" + mes + "/" + year + " (" + hora + ":" + minutos + ")";
            }
      }
      return fechaBonita;
}
export function formatearOnlyFechaBonita(fechaJava) {
      let fechaBonita = "xx/xx/xx";
      if (fechaJava != null) {
            if (fechaBonita.length > 2) {
                  let dia = agregarCero(fechaJava[2]);
                  let mes = agregarCero(fechaJava[1]);
                  let year = agregarCero(fechaJava[0]);

                  year = String(year).substring(2, 4);

                  fechaBonita = dia + "/" + mes + "/" + year;
            }
      }
      return fechaBonita;
}
export function formateaOnlyHoraBonita(fechaJava) {
      let fechaBonita = "xx/xx/xx";
      if (fechaJava != null) {
            if (fechaBonita.length > 5) {
                  let dia = agregarCero(fechaJava[2]);
                  let mes = agregarCero(fechaJava[1]);
                  let year = agregarCero(fechaJava[0]);

                  year = String(year).substring(2, 4);

                  let hora = agregarCero(fechaJava[3]);
                  let minutos = agregarCero(fechaJava[4]);
                  let segundos = agregarCero(fechaJava[5]);

                  fechaBonita = "(" + hora + ":" + minutos + ")";
            }
      }
      return fechaBonita;
}
export function agregarCero(num) {
      let rta = "";

      if (isNumeric(num)) {
            if (num < 10) {
                  rta = "0" + num;
            } else {
                  rta = num;
            }
      }
      return rta;
}
