{#if outline}
      <button
            class="boton-nico-2 outline"
            on:click={fnClick}
            style="border: 2px solid {bgColor}; background-color: transparent; color: {color};"
      >
            {#if cargando}
                  <IconoNico name="spinner" color={bgColor} />
            {:else}
                  <IconoNico name={icono} color={bgColor} />
            {/if}
            {#if texto}
                  <span>{texto}</span>
            {/if}
      </button>
{:else}
      <button
            class="boton-nico-2"
            on:click={fnClick}
            style="background-color: {bgColor}; color: {color};"
      >
            {#if cargando}
                  <IconoNico name="spinner" {color} />
            {:else}
                  <IconoNico name={icono} {color} />
            {/if}
            {#if texto}
                  <span>{texto}</span>
            {/if}
      </button>
{/if}

<script>
      import IconoNico from "../basics/IconoNico.svelte";

      export let texto = "";
      export let icono = "check";
      export let color = "white";
      export let cargando = false;
      export let bgColor = "#7f56da";
      export let outline = false;

      export let fnClick = () => {
            console.log("FUNCION NO ASOCIADA AL BOTÓN - DEBO CARGARLA POR FUERA DEL COMPONENTE:");
      };
</script>

<style>
      .boton-nico-2 {
            border-radius: 50px;
            padding: 10px 20px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            gap: 10px; /* Espacio entre ícono y texto */
            font-size: 16px;
            font-weight: bold;
            cursor: pointer;
            transition:
                  background-color 0.3s,
                  color 0.3s;
      }

      .boton-nico-2:hover {
            opacity: 0.8;
      }

      .boton-nico-2:disabled {
            opacity: 0.5;
            cursor: not-allowed;
      }

      .boton-nico-2.outline {
            background-color: transparent;
      }
</style>
