<script>
      import BotonNico from "../componentes/basics/BotonNico.svelte";
      import Input from "../componentes/basics/Input.svelte";
      import Pagina from "../componentes/formato/PaginaBase.svelte";

      let pass1 = "";
      let pass2 = "";
      function validarClavesIdenticas() {
            let validado = false;
            if (pass1 === pass2) {
                  validado = true;
            } else {
                  alert("Ambas Claves deben ser identicas..");
            }
      }
      function cambiarClave() {
            if (validarClavesIdenticas) {
                  // http request a back:
            }
      }
</script>

<Pagina>
      <div class="cont-change-password col-12 p-3">
            <h3>Cambiar Clave del usuario</h3>

            <Input bind:value={pass1} label="Clave nueva" type="password" />
            <Input bind:value={pass2} label="Repite Clave" type="password" />
            <BotonNico texto="Cambiar Clave" fnClick={validarClavesIdenticas} />
      </div>
</Pagina>
