<script>
      export let texto = "Lorem ipsum dolor sit amet.";
      export let fontSize = "14px";
      export let fontFamily = "poppins";
      export let clases = "";
      export let pl = "0px";
      export let pr = "0px";
      export let pt = "0px";
      export let pb = "0px";
      export let style = "";
</script>

<div class="cont-h">
      <h3 class="real-h {clases} {fontFamily} clases" style="font-size: 14px; {style}">
            {texto}
      </h3>
</div>

<style>
      .real-h {
            font-weight: thin;
            color: #aeafb0;
      }
</style>
