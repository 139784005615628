{#if visible}
      <div class="tostada flex-center-h col-12 poppins" class:show={message}>
            <div class="centrador-tostada" style="background-color: {color};">
                  <span class="icon">
                        <IconoNico name={icon} color="white" />
                  </span>
                  <div class="message">{message}</div>
            </div>
      </div>
{/if}

<script>
      import { notification } from "../../stores/notificationStore"; // Importamos el store
      import IconoNico from "./IconoNico.svelte"; // Si usas un componente para el ícono

      // Suscribirse a las notificaciones
      let message = "Tu mensaje aqui!";
      let color = "red";
      let icon = "check";
      let visible = true;

      // Suscribirse a las notificaciones
      $: {
            const currentNotification = $notification;
            message = currentNotification.message;
            icon = currentNotification.icon;
            color = currentNotification.color;
            visible = currentNotification.visible;
      }
</script>

<style>
      .tostada {
            position: fixed;
            bottom: 50px;
            z-index: 99999999999;
            gap: 10px;
            padding: 10px;
            border-radius: 8px;
            opacity: 0;
            animation:
                  fadeIn 0.5s ease,
                  fadeOut 0.5s ease 2.5s forwards;
      }
      .centrador-tostada {
            background-color: red;
            color: white;
            padding: 10px;
            border-radius: 75px;
            width: fit-content;
      }

      .tostada.show {
            display: flex; /* Mostramos la tostada cuando hay un mensaje */
            opacity: 1;
      }

      .centrador-tostada {
            display: flex;
            align-items: center;
            color: white;
            padding: 15px;
            border-radius: 25px;
            width: fit-content;
            font-size: 16px;
            font-weight: bold;
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
      }

      .icon {
            margin-right: 12px;
            font-size: 20px;
      }

      .message {
            flex: 1;
            word-wrap: break-word;
      }

      @keyframes fadeIn {
            from {
                  opacity: 0;
                  transform: translateY(100px);
            }
            to {
                  opacity: 1;
                  transform: translateY(0);
            }
      }

      @keyframes fadeOut {
            from {
                  opacity: 1;
            }
            to {
                  opacity: 0;
                  transform: translateY(100px);
            }
      }
</style>
