{#if modalOpen}
      <div class="overlay flex-center-h flex-center-v">
            <div class="modal-nico" style="height: {h};width: {w};">
                  <div
                        class="header-modal flex"
                        style="background-color:{bgColorTitle}; color:{textColorTitle}"
                  >
                        <div class="cont-titulo-header flex-center-v">
                              <div class="titulo-modal flex-center-v">
                                    {title}
                              </div>
                        </div>
                        <div
                              class="cont-times-header flex-center-h flex-center-v"
                              style="background-color:rgba(0,0,0,0.25)"
                              on:click={closeModal}
                              on:keypress={closeModal}
                        >
                              <IconoNico name="cross" />
                        </div>
                  </div>
                  <div class="body-modal">
                        <!-- SEL: {JSON.stringify(selected)} -->
                        {#if ContentComponent}
                              <svelte:component this={ContentComponent} {selected} {propsSlot} />
                        {/if}
                  </div>
                  <div class="footer-modal flex col-12 sin-padding">
                        <div class="col-6 flex flex-center-h flex-center-v">
                              <BotonNico
                                    clasesCont="col-12 flex-start pt-0 p-2"
                                    clases=""
                                    texto="Regresar"
                                    icono={iconRetroceder}
                                    fnClick={$BOTONERA_MODAL_STORE.back}
                                    outline="true"
                              />
                              <!-- fnClick={closeModal} -->
                        </div>
                        <div class="col-6 flex flex-end flex-center-v">
                              <BotonNico
                                    clasesCont="  pt-0 p-2"
                                    clases="col-12"
                                    texto={textoAvanzar}
                                    fnClick={$BOTONERA_MODAL_STORE.proceed}
                                    icono={iconAvanzar}
                              />
                        </div>
                        <!-- fnClick={closeModal} -->
                  </div>
            </div>
      </div>
{/if}

<!-- Modal.svelte -->
<script>
      import { facturaSelectedSTORE } from "../../stores/factura-selected-store";
      import { BOTONERA_MODAL_STORE, proceed, back } from "../../stores/modal-store";
      import BotonNico from "../basics/BotonNico.svelte";
      import IconoNico from "../basics/IconoNico.svelte";

      export let title = "Modal";
      export let modalOpen = false;
      export let bgColorTitle = "#7f56da";
      export let textColorTitle = "white";
      // export let facturaSelected = getContext("facturaSelected");
      export let w = "500px";
      export let h = "750px";
      export let ContentComponent = null; // Recibe un componente como prop
      export let textoAvanzar = "Listo";
      export let iconAvanzar = "check";
      export let iconRetroceder = "chevron-left";
      export let selected;
      export let propsSlot = {};
      // $: selected = $facturaSelectedSTORE;
      function closeModal() {
            modalOpen = false;
      }

      let ejecutarFuncion;

      // Nos suscribimos al store para obtener la función
      $: {
            ejecutarFuncion = $BOTONERA_MODAL_STORE;
      }
</script>

<style>
      .overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1000; /* Asegúrate de que esté encima de otros elementos */
      }

      .modal-nico {
            border-radius: 6px;
            overflow: hidden;
            background-color: white;
            /* padding: 20px; */
            /* border-radius: 8px; */
            z-index: 1999;
            /* width: 500px; */
            max-height: 100vh;
            max-width: 100vw;

            /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); */
      }
      .header-modal {
            background-color: #e3e3e3;
            height: fit-content;
            /* height: 60px; */
            padding: 12px;
            /* padding-bottom: 25px; */
      }
      .body-modal {
            padding: 20px;
            overflow-y: auto;
            overflow-x: auto;
            height: calc(100% - 115px);
      }
      .body-modal::-webkit-scrollbar {
            width: 6px; /* Ancho de la barra de desplazamiento vertical */
            height: 6px; /* Alto de la barra de desplazamiento horizontal */
      }
      .body-modal::-webkit-scrollbar-track {
            background: #e3e3e3; /* Color de fondo del riel */
            border-radius: 10px; /* Bordes redondeados */
      }
      .body-modal::-webkit-scrollbar-thumb {
            background: #7f56da; /* Color del deslizador */
            border-radius: 10px; /* Bordes redondeados */
      }
      .footer-modal {
            background-color: #e3e3e3;
            height: 60px;
            padding: 8px;
      }
      .cont-titulo-header {
            height: fit-content;
            width: calc(100% - 20px);
      }
      .titulo-modal {
            font-weight: bold;
            font-size: 22px;
      }
      .cont-times-header {
            padding-top: 0px;
            padding-left: 6px;
            padding-right: 6px;
            /* border: solid 1px rgb(206, 205, 205); */
            border-radius: 5px;
            /* height: 35px; */
            /* height: 35px; */
            border-radius: 6px;
            cursor: pointer;
            margin-top: 0px;
      }
</style>
