<script>
      import Pagina from "../componentes/formato/PaginaBase.svelte";
      import IconoNico from "../componentes/basics/IconoNico.svelte";
</script>

<Pagina>
      Emails
      <!-- <IconoNico name="cross" color="blue" /> -->
      <!-- <IconoNico name="wand-magic-sparkles" color="green" /> -->

      <div class="flex">
            <!-- <IconoNico2 name="check" color="green" /> -->
            <IconoNico name="check" color="blue" />
            <IconoNico name="cross" color="blue" />
            <IconoNico name="cloud" color="blue" />
            <IconoNico name="user" color="grey" />
            <IconoNico name="magic-wand" color="red" />
            <IconoNico name="bars" color="green" />
            <IconoNico name="mp" color="#7f56da" />
            <IconoNico name="credit-card" color="#7f56da" />
            <!-- <IconoNico2 name="cross" color="blue" /> -->
      </div>
</Pagina>
